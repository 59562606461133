/*
File: store/rbac/rbac.js
Description: API connections for RBAC.
*/
import Api from '@/api/rbac'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    rolesList: [],
    userRolesList: [],
    permissionsList: [],
    userPermissionsList: [],
    apiPathList: []
  },

  actions: {
    async LOAD_ROLES_LIST({ commit }, forDropdown = false) {
      return await handleApiCall({
        request: Api.load_roles_list(),
        caller: 'LOAD_ROLES_LIST',
        commit: !forDropdown ? commit : null,
        mutation: !forDropdown ? 'SET_ROLES_LIST' : null
      });
    },

    async GET_ROLE_BY_ID({ commit }, id) {
      return await apiCall(Api.get_role_by_id(id), 'GET_ROLE_BY_ID');
    },

    async ADD_ROLE({ commit }, role) {
      return await apiCall(Api.add_role(role), 'ADD_ROLE', 201);
    },

    async UPD_ROLE({ commit }, { id, role }) {
      return await apiCall(Api.upd_role(id, role), 'UPD_ROLE', 201);
    },

    async DEL_ROLE({ commit }, id) {
      return await handleApiCall({
        request: Api.del_role(id),
        caller: 'DEL_ROLE',
        //commit,
        //mutation: 'DEL_ROLE_FROM_LIST'
      });
    },

    /*--------- PERMISSIONS ---------------------*/
    async LOAD_USER_PERMISSIONS_PIVOT({ commit }) {
      return await handleApiCall({
        request: Api.load_permissions_pivot(),
        caller: 'LOAD_USER_PERMISSIONS_PIVOT',
        commit,
        mutation: 'SET_PERMISSIONS_LIST'
      });
    },

    async GET_USER_PERMISSIONS_ANONYMOUS({ commit }) {
      return await apiCall(Api.get_permissions_anonymous(), 'GET_USER_PERMISSIONS_ANONYMOUS');
    },

    async GET_USER_PERMISSIONS({ commit }, user_id) {
      return await handleApiCall({
        request: Api.get_permissions_by_user(user_id),
        caller: 'GET_USER_PERMISSIONS',
        commit,
        mutation: 'SET_USER_PERMISSIONS_LIST'
      });
    },

    /*--------- USER ROLES ---------------------*/
    async LOAD_USER_ROLES({ commit }, filters) {
      const { role_id, user_id } = filters
      return await handleApiCall({
        request: Api.load_user_roles(role_id, user_id),
        caller: 'LOAD_USER_ROLES',
        commit,
        mutation: 'SET_USER_ROLES_LIST'
      });
    },

    async GET_USER_ROLE_BY_ID({ commit }, user_role_id) {
      return await apiCall(Api.get_user_role_by_id(user_role_id), 'GET_USER_ROLE_BY_ID');
    },

    async ADD_USER_ROLE({ commit }, user_role) {
      return await apiCall(Api.add_user_role(user_role), 'ADD_USER_ROLE', 201);  //?.role_id;
    },

    async DEL_USER_ROLE({ commit }, user_role_id) {
      return await apiCall(Api.del_user_role(user_role_id), 'DEL_USER_ROLE');
    },

    /*--------- API PATH ---------------------*/
    async LOAD_API_PATH_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_api_path_list(),
        caller: 'LOAD_API_PATH_LIST',
        commit,
        mutation: 'SET_API_PATH_LIST'
      });
    },

    async GET_API_PATH_BY_ID({ commit }, api_path_id) {
      return await apiCall(Api.get_api_path_by_id(api_path_id), 'GET_API_PATH_BY_ID');
    },

    async GET_API_URL_MAP({ commit }) {
      return await apiCall(Api.get_api_url_map(), 'GET_API_URL_MAP');
    },

    async ADD_API_PATH({ commit }, api_path) {
      return await apiCall(Api.add_api_path(api_path), 'ADD_API_PATH', 201);
    },

    async UPD_API_PATH({ commit }, { api_path_id, api_path }) {
      return await apiCall(Api.upd_api_path(api_path_id, api_path), 'UPD_API_PATH', 201);
    },

    async SYNC_API_PATH({ commit }) {
      return await apiCall(Api.sync_api_path(), 'SYNC_API_PATH', 201);
    },

    async DEL_API_PATH({ commit }, api_path_id) {
      return await apiCall(Api.del_api_path(api_path_id), 'DEL_API_PATH');
    },
  },

  mutations: {
    SET_ROLES_LIST: (state, rolesList) => {
      state.rolesList = [...rolesList]
    },
    SET_USER_ROLES_LIST: (state, rolesList) => {
      state.userRolesList = [...rolesList]
    },
    SET_PERMISSIONS_LIST: (state, list) => {
      state.permissionsList = [...list]
    },
    SET_USER_PERMISSIONS_LIST: (state, list) => {
      state.userPermissionsList = [...list]
    },
    SET_API_PATH_LIST: (state, list) => {
      state.apiPathList = [...list]
    },
  },

  getters: {
    getUserRole: (state) => (groupId) => {
      return state.rolesList.find(role => role.role_id === groupId)?.role_desc
    },

    getPermissionsByType: (state) => (type) => {
      return state.permissionsList.filter(item => !type || item.component_type === type)
    },

    getPermissionTypes: (state) => {
      // Get all unique types in the list, order and return as an  array of objects for BaseDropdown
      const uniqueTypes = [...new Set(state.permissionsList.reduce((acc, item) => {
        if (item.component_type !== null) acc.push(item.component_type)
        return acc
      }, []))]
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        .map(name => ({ name }));

      uniqueTypes.unshift({ name: '' })   // Add null value to the beginnig
      return uniqueTypes
    },

    userPermissionsLoaded: (state) => state.userPermissionsList.length > 0,

    getFormPermissions: (state) => (formName) => {
      return state.userPermissionsList.filter(item =>
        //(item.component_type === 'Screen') &&
        (!formName || item.form_name === formName)
      )
    },

    isAllowed: (state) => (formName, type, name) => {
      const res = state.userPermissionsList
        .find(item => item.form_name === formName && item.component_type === type && item.component_name === name)
        ?.front_component_id;
      return !!res;
    },

    isScreenAllowed: (state, getters) => (screenName) => {
      return getters.isAllowed(screenName, 'Screen', 'Form');
    },

    getApiMethodsList: (state) => (addEmptyLine = true) => {
      // Get all unique methods in the list, order and return as an  array of objects for BaseDropdown
      const uniqueTypes = [...new Set(state.apiPathList.reduce((acc, item) => {
        if (item.method !== null) acc.push(item.method)
        return acc
      }, []))]
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        .map(description => ({ id: description, description }));

      if (addEmptyLine) uniqueTypes.unshift({ id: '', description: '' })   // Add null value to the beginnig
      return uniqueTypes
    },

    getApiPathByMethod: (state) => (method) => {
      return state.apiPathList.filter(item => !method || item.method === method)
    },

  }
}
