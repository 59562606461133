/* 
File: index.js
Description: main file containig all the componens
*/
/*** Old one's ***/
import Vue from 'vue'
import Vuex from 'vuex'
import i18nStore from './i18n'
import Login from './login'
import Groups from './rbac/groups'
import Users from './rbac/users'
import Dashboard from './dashboard'


import RBAC from './rbac/rbac'

/*** Inventory: ***/
import RoadNetwork from './inventory/roadnetwork'
import Sections from './inventory/sections'
import HomoSections from './inventory/homo_sections'
import Condition from './inventory/condition'
import ImportTools from './inventory/import'

/*** WorkPlan ***/
import Plans from './workplan/plans'
import TitleList from './workplan/titlelist'
import Budgets from './workplan/budget'

/*** Maps ***/
import ParamsComponent from './maps/params_component'
import MapComponent from './maps/map_component'
import ImagesComponent from './maps/images_component'
import Bridges from './maps/bridges_tonnels'

/*** Reference data: ***/
import RefData from './refdata/refdata'
import Dropdowns from './refdata/dropdowns'
import WorkCategories from './refdata/workcategories'
import Inventory from './refdata/inventory'
import Traffic from './refdata/traffic'

/*** Settings: ***/
import Reports from './settings/reports'
import Treatments from './settings/treatments'
import Settings from './settings/settings'
import History from './settings/history'
import ConditionIndexCriteria from './settings/condition_index_criteria'

import Pictures from './test_dev/pictures'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: process.env.VUE_APP_VERSION
  },
  actions: {},
  modules: {
    /*** Old one's ***/
    i18nStore,
    Login,
    Groups,
    Users,
    Bridges,
    Dashboard,

    RBAC,

    /*** Inventory: ***/
    RoadNetwork,
    Sections,
    Condition,
    HomoSections,
    ImportTools,
    Reports,

    /*** Workplan: ***/
    Budgets,
    Plans,
    TitleList,

    /*** Maps ***/
    ParamsComponent,
    MapComponent,
    ImagesComponent,

    /*** Reference data: ***/
    Dropdowns,
    RefData,
    Treatments,
    WorkCategories,
    Inventory,
    Traffic,

    /*** Settings: ***/
    ConditionIndexCriteria,
    Settings,
    History,

    Pictures
  }
})
