<!--
File: Map.vue
Description: shows map with roads entered in the DB.
-->
<template>
  <div class="grid-container">
    <md-card class="q1">
      <MapSelectParams />
    </md-card>

      <!-- Here is the place for the Map -->
      <KG_Map class="q2 maps-area" ref="mapInstance" :tileLayer="selectedTileType" :mapDefaultParams="mapDefaultParams"
        :trackedEvents="['singleclick']" />

      <!-- Here is the place for the Chart -->
      <MapCharts class="q3 md-layout" />

    <div >
      <!-- Here is the place for the Picture -->
      <RoadImages class="q4" :item_id="item_id" :pic_id="picId" :vectorLayer="vector" />
    </div>
  </div>
</template>
<script>
  import { onClose } from '@/mixins/onCloseMixin'
  import permissions from "@/mixins/permissionsMixin"
  import MapSelectParams from "./MapSelectParams.vue"
  import KG_Map from './KG_Map.vue'
  import MapCharts from "./MapCharts.vue"
  import RoadImages from "./RoadImages.vue"

  export default {
    name: 'map-main',
    mixins: [permissions],

    data() {
      return {
        formName: 'Map',

        // Select params
        item_id: null,      // can be section_id or uuid

        // Map params
        selectedTileType: 'osm',
        vector: 'roads',
        mapDefaultParams: {
          dataProjection: 'EPSG:32643',     //EPSG:4326',
          map_centre: [494000, 4580000],    //[75.20484, 41.572522],
          max_zoom: 16,
          viewProjection: 'EPSG:4326',      //'EPSG:32643',
          zoom: 7,
          //min_zoom: 5
        },

        // Picture params
        picId: 0,
      }
    },

    components: {
      MapSelectParams,
      KG_Map,
      MapCharts,
      RoadImages
    },

    async beforeMount() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.onClose()
        //return
      };
    },

    methods: {
      onClose,
    }
  }
</script>
<style lang="scss" scoped>
.q1 {
  grid-column: 1 / 1;
  grid-row: 1 / span 4;
  overflow: scroll;
}

.q2 {
  grid-column: 2 / span 3;
  grid-row: 1 / span 3;
}

.q3 {
  grid-column: 2 / 2;
  grid-row: 4 / 4;
}

.q4 {
  grid-column: 3 / 3;
  grid-row: 4 / 4
}

.grid-container {
  position: absolute;
  padding: 0 12px;
  left: 0;
  height: calc(100% - 100px);
  width: 100%;
  display: grid;
  grid-template-columns: 30% 35% 35%;
  grid-template-rows: 25% 25% 20% 25%;
}

.maps-area {
  position: static;
  padding: 0 12px;
  left: 0;
  //height: calc(100% - 100px);
  width: 100%;
}
</style>