<!--
File: RegionEditForm.vue
Description: show details of a selected road section as a dialog window.
-->
<template>
  <modal>
    <template slot='header'>
      <h4 class='modal-title'>{{ screenTitle }}</h4>
      <md-button class='md-simple md-just-icon md-round modal-default-button' @click='onModalClose'>
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('region_key')">
          <label for="region_key">{{ $t('road_network.region_key') }}</label>
          <md-input id="region_key" v-model="region_key" type="text" data-vv-name="region_key" required
            v-validate="modelValidations.region_key" />
        </md-field>

        <md-field :class="getClass('region_description')">
          <label for="region_description">{{ $t('stdCols.name_ru') }}</label>
          <md-input id="region_description" v-model="region_description" type="text" data-vv-name="region_description"
            required v-validate="modelValidations.region_description" />
        </md-field>
        <md-field :class="getClass('region_description_en')">
          <label for="region_description_en">{{ $t('stdCols.name_en') }}</label>
          <md-input id="region_description_en" v-model="region_description_en" type="text"
            data-vv-name="region_description_en" required v-validate="modelValidations.region_description_en" />
        </md-field>

        <md-field :class="getClass('regional_dep')">
          <label for="regional_dep">{{ $t('road_network.regional_office') + ' (RU)' }}</label>
          <md-input id="regional_dep" v-model="regional_dep" type="text" data-vv-name="regional_dep" required
            v-validate="modelValidations.regional_dep" />
        </md-field>
        <md-field :class="getClass('regional_dep_en')">
          <label for="regional_dep_en">{{ $t('road_network.regional_office') + ' (EN)' }}</label>
          <md-input if="regional_dep_en" v-model="regional_dep_en" type="text" data-vv-name="regional_dep_en" required
            v-validate="modelValidations.regional_dep_en" />
        </md-field>
        <md-field style="width: 50px; top: -7px">
          <md-switch v-model="active" id="active">{{ $t('road_network.active') }}</md-switch>
        </md-field>
      </div>

      <span v-if="saveBtnDisabled" class="md-error">
        {{ $t('messages.errors_count', { count: errors.count() }) }}
      </span>
    </template>

    <template slot='footer'>
      <md-button v-if="isBtnAllowed('SaveButton')" class="md-success" native-type="submit"
        @click.native.prevent="validate" :disabled="saveBtnDisabled">
        {{ $t('buttons.save') }}
      </md-button>
      <md-button class="md-accent" @click.stop.prevent="onModalClose">
        {{ $t('buttons.cancel') }}
      </md-button>
    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal } from '@/components'
  import { onModalClose } from '@/mixins/onCloseMixin'
  import messages from '@/mixins/messagesMixin'
  import permissions from "@/mixins/permissionsMixin"

  export default {
    name: 'region-edit-form',
    mixins: [messages, permissions],

    data() {
      return {
        formName: 'RegionEditForm',
        screenTitle: '',

        region_key: null,
        region_description: null,
        region_description_en: null,
        regional_dep_en: null,
        regional_dep: null,
        active: null,

        modelValidations: {
          region_key: { required: true, min: 3 },
          region_description: { required: true, min: 3 },
          region_description_en: { required: true, min: 3 },
          regional_dep_en: { required: true, },
          regional_dep: { required: true, min: 3 },
        }
      }
    },

    props: {
      regionId: null
    },

    components: {
      Modal
    },

    async mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onModalClose()
        return
      };

      if (this.regionId) {
        this.screenTitle = this.$t('route.regions_upd')
        // Load data on existing region
        this.loadRegion(this.regionId).then((res) => {
          const theRegion = Array.isArray(res) ? res[0] : res
          this.region_key = theRegion.region_key
          this.region_description = theRegion.region_description
          this.region_description_en = theRegion.region_description_en
          this.regional_dep = theRegion.regional_dep
          this.regional_dep_en = theRegion.regional_dep_en
          this.active = theRegion.active
        })
      } else {
        this.screenTitle = this.$t('route.regions_add')
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadRegion: 'LOAD_RDB_REGION_BY_ID',
        addRegion: 'ADD_RDB_REGION',
        editRegion: 'UPD_RDB_REGION',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      onModalClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theRegion = {
          fk_rn: 1,
          region_key: this.region_key,
          region_description: this.region_description,
          region_description_en: this.region_description_en,
          regional_dep: this.regional_dep,
          regional_dep_en: this.regional_dep_en,
          active: this.active
        }

        let errDesc = '';
        let newRegionId;
        const action = !this.regionId ? this.addRegion : this.editRegion;
        const payload = !this.regionId ? this.theRegion : { id: this.regionId, theRegion };
        try {
          const res = await action(payload)
          newRegionId = res?.region_id;
        } catch (err) {
          errDesc = err.message
        }

        this.$nextTick(() => this.$validator.reset())
        this.onModalClose()
        await this.savedMessage(errDesc, this.$t('road_network.region'), this.region_description, this.region_description_en)
        this.highlightRow(newRegionId)
      }
    },
  }
</script>
<style lang='scss' scoped>
.md-button+.md-button {
  margin-left: 10px;
}
</style>