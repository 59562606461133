<!--
File: TrafficSites.vue
Description: show list of traffic sites entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadsDropdown v-model="selectedRoad" :items="roadsInTrafficSitesList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem()">
            {{ $t('buttons.add') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}"
          :class="{ blinking: item.traffic_site_id === highlightedRow }"
          :id="item.traffic_site_id === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell :md-label="$t('road_network.road')" md-sort-by="sdr_road">
            {{ item.sdr_road }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.section')" md-sort-by="section">
            {{ item.section }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="traffic_site_desc">
            {{ item.traffic_site_desc }}
          </md-table-cell>
          <md-table-cell :md-label="$t('inventory.start_distance_m')" md-sort-by="start_distance">
            {{ item.start_distance | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('inventory.end_distance_m')" md-sort-by="end_distance">
            {{ item.end_distance | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.surveyed_distance')" md-sort-by="surveyed_distance">
            {{ item.surveyed_distance | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_coef_set')" md-sort-by="aadt_coef_set">
            {{ item.aadt_coef_set }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="viewItem(item.traffic_site_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="isBtnAllowed('DeleteButton')" class="md-just-icon md-danger md-simple"
                :title="$t('buttons.delete')"
                @click.stop.prevent="deleteItem(item.traffic_site_id, item.traffic_site_desc)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
    <EditForm v-if="showDetailsDlg" :trafficSiteId="trafficSiteId" @close="showDetailsDlg = false" />
  </md-card>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { Pagination } from '@/components'
  import permissions from "@/mixins/permissionsMixin"
  import listView from '@/mixins/listViewMixin'
  import RoadsDropdown from '@/pages/Components/RoadsDropdown.vue'
  import messages from '@/mixins/messagesMixin'
  import Fuse from 'fuse.js'
  import EditForm from './TrafficSiteEditForm.vue'

  export default {
    name: 'traffic-sites-all',
    mixins: [permissions, listView, messages],

    data() {
      return {
        formName: 'TrafficSites',

        selectedRegion: 0,
        selectedRoad: 0,
        showSpinner: false,
        showDetailsDlg: false,
        trafficSiteId: null,

        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['traffic_site_desc', 'sdr_road', 'section'],
        currentSort: 'traffic_site_desc',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },

    components: {
      Pagination,
      RoadsDropdown,
      EditForm
    },

    mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onClose()
        return
      };

      this.showSpinner = true
      this.loadHistory().then((history) => {
        if (history.form === 'trafficsites' && history.use) {
          this.selectedRegion = history.data.selectedRegion
          this.selectedRoad = history.data.selectedRoad
          this.searchQuery = history.data.searchQuery
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          //this.highlightedRow = history.row
          this.$nextTick(() => {
            this.pagination.currentPage = history.data.currentPage
          })
          this.clearHistory()
          this.showSpinner = false
        }
        this.reloadData()
      })
    },

    methods: {
      ...mapActions({
        loadList: 'LOAD_TRAFFIC_SITES',
        delete: 'DEL_TRAFFIC_SITE',
      }),

      async reloadData() {
        this.showSpinner = true
        await this.loadList();
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.1 })
        this.showSpinner = false
      },

      save_history() {
        const hist = {
          form: 'trafficsites',
          data: {
            selectedRegion: this.selectedRegion,
            selectedRoad: this.selectedRoad,
            searchQuery: this.searchQuery,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage
          }
        }
        this.saveHistory(hist)
      },

      viewItem(id) {
        this.trafficSiteId = id
        this.showDetailsDlg = true
      }
    },

    computed: {
      ...mapState({
        highlightedRow: (state) => state.History.row
      }),

      ...mapGetters(['trafficSitesFiltered', 'roadsInTrafficSitesList']),

      tableData() {
        return this.trafficSitesFiltered(this.selectedRoad)
      },
    },

    watch: {
      async highlightedRow(newVal) {
        if (newVal) {
          await this.reloadData();
          // calculate - which page edited item belongs to
          const ind = this.searchedData.findIndex(item => item.traffic_site_id == newVal)
          this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
          this.scrollToHighlightedRow();
          this.clearHistory();
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>