<!--
File: Sections.vue
Description: show list of road sections entered in the DB.
-->
<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RegionsDropdown v-model="selectedRegion" @input="reloadData" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <DepsDropdown v-model="selectedDeu" :regionId="selectedRegion" :disabled="!selectedRegion" @input="refresh" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RoadsDropdown v-model="selectedRoad" :items="roadsList" :disabled="!selectedRegion" @input="refresh" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <md-input type="search" class="mb-3" clearable :placeholder="$t('label.search_records')"
                v-model="searchQuery" :disabled="!selectedRegion" />
            </md-field>
          </div>

          <div class="md-layout-item btn-row md-small-size-100">
            <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem()">
              {{ $t('buttons.add') }}
            </md-button>
            <md-button v-if="closePath" class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-progress-spinner v-if="showSpinner == true" :md-diameter="100" md-mode="indeterminate" />
        <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item.section_id === highlightedRow }"
            :id="item.section_id === highlightedRow ? 'highlightedRow' : null">
            <md-table-cell :md-label="$t('road_network.dep')" md-sort-by="deu">
              {{ item.deu }}
            </md-table-cell>
            <md-table-cell :md-label="$t('road_network.road_key')" md-sort-by="road">
              {{ item.road_key }}
            </md-table-cell>
            <md-table-cell style="min-width: 400px;" :md-label="$t('stdCols.name')" md-sort-by="section_description">
              {{ item.section_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('inventory.start_distance_m')" md-sort-by="start_distance_m" md-numeric>
              {{ item.start_distance_m | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('inventory.end_distance_m')" md-sort-by="end_distance_m" md-numeric>
              {{ item.end_distance_m | numFormat(0) }}
            </md-table-cell>

            <md-table-cell :md-label="$t('tables.actions')">
              <div>
                <md-button class="md-just-icon md-success md-simple" :title="$t('buttons.details')"
                  @click="showDetails(item)">
                  <md-icon>preview</md-icon>
                </md-button>
                <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                  @click.native="viewItem(item.section_id)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button v-if="isBtnAllowed('DeleteButton')" class="md-just-icon md-danger md-simple"
                  :title="$t('buttons.delete')"
                  @click.stop.prevent="deleteItem(item.section_id, item.section_description)">
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div class>
          <p class="card-category">
            {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
          </p>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label for="pages">{{ $t('label.per_page') }}</label>
            <md-select v-model="pagination.perPage" name="pages">
              <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
          :per-page="pagination.perPage" :total="total"></pagination>
      </md-card-actions>
    </md-card>

    <SectionDetails v-if="showDetailsDlg" :sectionId="section.section_id" @close="showDetailsDlg = false" />
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { Pagination } from '@/components'
  import { RegionsDropdown, DepsDropdown, RoadsDropdown } from '@/pages/Components'
  import messages from '@/mixins/messagesMixin'
  import permissions from "@/mixins/permissionsMixin"
  import listView from '@/mixins/listViewMixin'
  import SectionDetails from './SectionDetails.vue'

  import Fuse from 'fuse.js'

  export default {
    name: 'sections-list',
    mixins: [messages, permissions, listView],

    data() {
      return {
        formName: 'Sections',
        highlightedRow: null,

        selectedRegion: null,
        selectedDeu: null,
        selectedRoad: null,
        showDetailsDlg: false,
        section: {},
        showSpinner: false,
        deuLabel: '',

        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['deu', 'road', 'section_description'],
        currentSort: 'deu',
        currentSortOrder: 'asc',
      }
    },

    components: {
      Pagination,
      RegionsDropdown,
      DepsDropdown,
      RoadsDropdown,
      SectionDetails
    },

    mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onClose()
        return
      };

      this.showSpinner = true
      this.clearSectionsList()
      this.deuLabel = this.$t('road_network.dep')

      this.loadHistory().then((history) => {
        if (history.form === 'sections' && history.use) {
          this.selectedRegion = history.data.selectedRegion
          this.selectedDeu = history.data.selectedDeu
          this.selectedRoad = history.data.selectedRoad
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.highlightedRow = history.row
          this.$nextTick(() => {
            this.searchQuery = history.data.searchQuery
            this.pagination.currentPage = history.data.currentPage
          })
          this.clearHistory()
          // Set the timer for highlighting for 5 sec
          if (this.highlightedRow) setTimeout(() => { this.highlightedRow = null; }, 5000);
        }
        this.reloadData()
        this.showSpinner = false
      })
    },

    methods: {
      ...mapActions({
        loadList: 'LOAD_SECTIONS_LIST',
        clearSectionsList: 'CLEAR_SECTIONS_LIST',
        delete: 'DEL_SECTION',
      }),

      async reloadData() {
        this.showSpinner = true
        const searchQuery = this.searchQuery
        this.searchQuery = ''
        await this.loadList({ region_id: this.selectedRegion, road_id: this.selectedRoad })
        if (searchQuery) this.searchQuery = searchQuery
        this.showSpinner = false
      },

      refresh() {
        if (!this.searchQuery) return

        this.showSpinner = true
        const searchQuery = this.searchQuery
        this.searchQuery = ''
        this.$nextTick(() => {
          if (searchQuery) this.searchQuery = searchQuery
        })
        this.showSpinner = false
      },

      save_history() {
        const hist = {
          form: 'sections',
          data: {
            selectedRegion: this.selectedRegion,
            selectedDeu: this.selectedDeu,
            selectedRoad: this.selectedRoad,
            searchQuery: this.searchQuery,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage
          }
        }
        this.saveHistory(hist)
      },

      showDetails(item) {
        this.section = item
        this.showDetailsDlg = true
      },

      viewItem(id) {
        this.save_history()
        const path = !id ? '/inventory_data/sections/add' : `/inventory_data/sections/upd/${id}`
        this.$router.push(path)
      },
    },

    computed: {
      ...mapGetters(['sectionsFiltered', 'roadsListFiltered', 'closePath']),

      tableData() {
        return this.customSort(this.sectionsFiltered(this.deuLabel, this.selectedRegion, this.selectedDeu, this.selectedRoad),
          'start_distance_m')
      },

      roadsList() {
        return this.roadsListFiltered(this.selectedDeu)
      },

      fuseSearch() {
        return new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.1 });
      }
    },

    watch: {
      highlightedRow(newVal) {
        if (newVal) {
          this.scrollToHighlightedRow();
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>