<!--
File: MapSelectParams.vue
Description: Shows the attributes of the road
uses https://github.com/MisterTaki/vue-table-with-tree-grid
-->
<template>
  <div class="md-layout">
    <md-progress-spinner v-if="showSpinner == true" :md-diameter="70" md-mode="indeterminate" />
    <div class="md-layout-item md-small-size-100 md-size-30">
      <BaseDropdown id='vectorsFilter' :label="$t('map.vector_types')" v-model="selectedVectorType"
        :items="dropdownLists(true)" :displayField="'description'" :valueField="'id'" />
    </div>
    <div class="md-layout-item md-small-size-100 md-size-30">
      <YearsDropdown :label="$t('road_network.survey_year')" v-model="survey_year" />
    </div>
    <div class="md-layout-item md-small-size-100 md-size-30">
      <md-button class="md-raised md-success" @click="onResetParams"> {{ $t('map.reset') }} </md-button>
    </div>

    <VueTabs>
      <v-tab :title="$t('tabs.map_view')">
        <TreeGrid class="md-layout-item md-small-size-100 md-size-100 treegrid" :data="viewGridData" :stripe="true"
          :columns="viewGridColumns" :tree-type="true" :expand-type="false" :selection-type="false">
          <template slot="value" slot-scope="scope">
            <!--template v-if="selectedVectorType === 'roads'">
              <a href="#" @click.stop.prevent="onViewGridClick(scope.row.name, scope.row.value, scope.row.label)">
                {{ scope.row.value }}
              </a>
            </template>
            <template v-else-->
            {{ scope.row.value }}
            <!--/template-->
          </template>
        </TreeGrid>
      </v-tab>

      <v-tab :title="$t('tabs.roads_list')" v-if="selectedVectorType === 'roads'">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <BaseDropdown :label="$t('map.color_by')" v-model="selectedParamType" :items="dropdownLists(false)"
              :disabled="paramsDropdownDisabled" :displayField="'description'" :valueField="'id'" />
            <!--RoadTypesDropdown :label="'Road type'" v-model="selectedRoadType" :disabled="selectedVectorType !== '!!!roads'"
         @input='onRoadTypeChanged' /-->
            <!--StyleTypesDropdown :label="'Show as'" v-model="selectedStyleType" :disabled="selectedVectorType !== 'roads'"
         @input='onStyleTypeChanged' /-->
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-checkbox v-model="zoomToSelection">{{ $t('map.zoom_to_selection') }}</md-checkbox>
          </div>
        </div>
        <TreeGrid class="md-layout-item md-small-size-100 md-size-100" :data="roadsInfo" :stripe="true" :tree-type="true"
          :columns="roadsListGridColumns" :expand-type="false" :selection-type="false">
          <template slot="value" slot-scope="scope">
            <a href="#" @click.stop.prevent="onViewGridClick(scope.row.param, scope.row.id, scope.row.label)">
              {{ scope.row.value }}
            </a>
          </template>
        </TreeGrid>
      </v-tab>
    </VueTabs>
  </div>
</template>

<script>
  import { VueTabs, VTab } from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'
  import { mapState, mapActions } from 'vuex'
  import { getVectorTypes } from '@/store/helpers/ranges_helper';
  import BaseDropdown from '@/pages/Components/BaseDropdown.vue'
  import YearsDropdown from '@/pages/Components/YearsDropdown.vue'
  import RoadTypesDropdown from '@/pages/Components/RoadTypesDropdown.vue'
  import StyleTypesDropdown from '@/pages/Components/StyleTypesDropdown.vue'
  import TreeGrid from 'vue-table-with-tree-grid'

  export default {
    name: 'map-attribute-selector-component',

    data() {
      return {
        section_id: null,
        uuid: null,   // for bridge or tunnel
        showSpinner: false,

        viewGridData: [
          { name: "sect_info", label: this.$t('bridge.general_information'), children: [] },
          { name: "survey_data", label: this.$t('route.condition_data'), children: [] },
          //          { name: "indices", label: 'Indices and Maintenance', children: [] }
        ],
        viewGridColumns: [
          //{ label: 'id', prop: 'id', width: '0%' },
          { label: this.$t('label.attribute'), prop: 'label', width: '50%' },
          { label: this.$t('settings.value'), prop: 'value', type: 'template', template: 'value', width: '50%' },
        ],
        roadsListGridColumns: [
          { label: '', prop: '', width: '50px' },
          {
            label: `${this.$t('road_network.road_class')} / ${this.$t('road_network.road')} / ${this.$t('road_network.section')}`,
            prop: 'value', type: 'template', template: 'value', width: 'auto'
          },
          //{ label: 'param', prop: 'param', width: '0' },
        ],
        selectedRoadType: 'Paved',
        selectedStyleType: 'point',
        selectedVectorType: 'roads',
        selectedParamType: 'road_class',
        paramsDropdownDisabled: true,
        survey_year: null,
        zoomToSelection: false,
        tabName: 'home'
      }
    },

    components: {
      VueTabs,
      VTab,
      YearsDropdown,
      BaseDropdown,
      RoadTypesDropdown,
      StyleTypesDropdown,
      TreeGrid
    },

    created() {
      this.$eventHub.$on('mapItemSelected', this.onMapItemSelected);
    },

    beforeDestroy() {
      this.$eventHub.$off('mapItemSelected')
    },

    async mounted() {
      await this.loadGridInfo()
    },

    methods: {
      ...mapActions({
        loadGridInfo: 'LOAD_ROADS_GRID_INFO',
        loadSectionInfo: 'FILL_SECTION_INFO',
        loadSectionSurveyInfo: 'FILL_TREE_GRID_SECTION_SURVEY_INFO',
        loadBridgeTunnelInfo: 'FILL_BRIDGE_TUNNEL_INFO',
      }),

      clearviewGridData() {
        this.viewGridData[0].children = []
        this.viewGridData[1].children = []
        this.paramsDropdownDisabled = true
      },

      reloadSectionInfo(section_id) {
        if (!section_id) return

        this.showSpinner = true
        const deuLabel = this.$t('road_network.dep')
        this.loadSectionInfo({section_id, deuLabel}).then(() => {
          this.viewGridData[0].children = this.mainInfo
          //this.reloadSectionSurveyInfo() //this.years[0].id)
        })
        this.showSpinner = false
      },

      reloadSectionSurveyInfo(coords) {
        this.showSpinner = true
        this.loadSectionSurveyInfo({ year: this.survey_year, coords }).then(() => {
          this.viewGridData[1].children = this.surveyInfo
        })
        this.showSpinner = false
      },

      reloadBridgeTunnelInfo(layer, uuid) {
        if (!uuid) return

        this.showSpinner = true
        this.loadBridgeTunnelInfo({ layer, uuid }).then(() => {
          this.viewGridData[0].children = this.mainInfo
          this.viewGridData[1].children = this.surveyInfo
        })
        this.showSpinner = false
      },

      onViewGridClick(itemName, itemValue, itemDesc) {
        this.paramsDropdownDisabled = false
        this.$eventHub.$emit('viewGridClicked', { layer: 'coloringLayer', filter: itemName, value: itemValue, desc: itemDesc })
      },

      onResetParams() {
        this.clearviewGridData()
        this.selectedVectorType = 'roads'
        this.selectedParamType = 'road_class'
        this.$eventHub.$emit('resetStatus', this.selectedVectorType)
      },

      onRoadTypeChanged(value) {
        this.$eventHub.$emit('roadTypeChange', value)
      },

      onStyleTypeChanged(styletype) {
        this.$store.commit('SET_STYLE_TYPE', styletype)
      },

      onStrokeWidthChanged(stroke) {
        this.$store.commit('SET_STROKE_WIDTH', stroke)
      },

      onMapItemSelected({ layer, item_id, clicked_coords }) {    //feature_data) {
        this.clearviewGridData()
        if (!item_id) return

        switch (layer) {    //this.selectedVectorType) {
          case 'roads':
            this.uuid = null
            this.section_id = item_id
            this.reloadSectionInfo(item_id)
            this.reloadSectionSurveyInfo(clicked_coords)
            this.paramsDropdownDisabled = false     // because browser hangs if we color by param  whole roadnetwork except for road classes
            break
          case 'bridges':
          case 'tunnels':
            this.uuid = item_id
            this.section_id = null
            this.reloadBridgeTunnelInfo(layer.substring(0, 6), item_id)   // get rid of 's' in the end
            break
        }
      },

      dropdownLists(forVectorTypes) {
        const res = getVectorTypes(forVectorTypes).map(item => ({ id: item.key, description: this.$t(item.value) }));
        return res
      },

    },

    computed: {
      ...mapState({
        mainInfoUntranslated: (state) => state.ParamsComponent.mainInfo,
        surveyInfoUntranslated: (state) => state.ParamsComponent.surveyInfo,
        roadsInfo: (state) => state.ParamsComponent.roadsInfo,
        indexInfo: (state) => state.ParamsComponent.indexInfo,
        years: (state) => state.ParamsComponent.years,
      }),

      mainInfo() {
        return this.mainInfoUntranslated.map(item => ({ ...item, label: this.$t(item.label) }))
      },

      surveyInfo() {
        return this.surveyInfoUntranslated.map(item => ({ ...item, label: this.$t(item.label) }))
      },

    },

    watch: {
      survey_year(newValue, oldValue) {
        //if (newValue !== oldValue && newValue) this.reloadSectionSurveyInfo() //newValue)
      },

      selectedVectorType(newValue, oldValue) {
        this.clearviewGridData()
        this.$eventHub.$emit('vectorLayerChanged', newValue)
      },

      selectedParamType(value) {
        this.$eventHub.$emit('paramTypeChanged', { layer: 'coloringLayer', value })
      },

      zoomToSelection(value) {
        this.$eventHub.$emit('zoomToSelection', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
body {
  padding: 20px 0;
}

.treegrid {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>