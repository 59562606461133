<!--
File: DeuEditForm.vue
Description: form for adding/editing a single DEU.
-->
<template>
  <modal>
    <template slot='header'>
      <h4 class='modal-title'>{{ screenTitle }}</h4>
      <md-button class='md-simple md-just-icon md-round modal-default-button' @click='onModalClose'>
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <RegionsDropdown :class="getClass('fk_region')" :label="$t('road_network.region')" v-model="fk_region"
          data-vv-name="fk_region" v-validate="modelValidations.fk_region" :is_required="true" />

        <div class="md-layout md-small-size-50 md-size-50">
          <div style="display: flex; align-items: center;">
            <span>ДЭУ-</span>
            <md-field :class="getClass('description')">
              <label :for="description">{{ $t('stdCols.name_ru') }}</label>
              <md-input :id="description" v-model="description" type="text" required data-vv-name="description"
                v-validate="modelValidations.description" />
            </md-field>
          </div>
          <span>&nbsp;</span>
          <div style="display: flex; align-items: center;">
            <span>DEU-</span>
            <md-field :class="getClass('description_en')">
              <label :for="description_en">{{ $t('stdCols.name_en') }}</label>
              <md-input :id="description_en" v-model="description_en" type="text" required data-vv-name="description_en"
                v-validate="modelValidations.description_en" style="flex: 1; border-left: none;" />
            </md-field>
          </div>
        </div>

        <md-field :class="getClass('address1')">
          <label :for="address1">{{ $t('stdCols.address') }} (1)</label>
          <md-input :id="address1" v-model="address1" type="text" required data-vv-name="address1"
            v-validate="modelValidations.address1" />
        </md-field>

        <md-field :class="getClass('address2')">
          <label :for="address2">{{ $t('stdCols.address') }} (2)</label>
          <md-input :id="address1" v-model="address2" type="text" data-vv-name="address2" />
        </md-field>

        <md-field :class="getClass('city')">
          <label :for="city">{{ $t('road_network.city') }}</label>
          <md-input :id="city" v-model="city" type="text" required data-vv-name="city"
            v-validate="modelValidations.city" />
        </md-field>
      </div>

      <span v-if="saveBtnDisabled" class="md-error">
        {{ $t('messages.errors_count', { count: errors.count() }) }}
      </span>
    </template>

    <template slot='footer'>
      <md-button v-if="isBtnAllowed('SaveButton')" class="md-success" native-type="submit"
        @click.native.prevent="validate" :disabled="saveBtnDisabled">
        {{ $t('buttons.save') }}
      </md-button>
      <md-button class="md-accent" @click.stop.prevent="onModalClose">
        {{ $t('buttons.cancel') }}
      </md-button>
    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal } from '@/components'
  import { onModalClose } from '@/mixins/onCloseMixin'
  import { RegionsDropdown } from '@/pages/Components'
  import messages from '@/mixins/messagesMixin'
  import permissions from "@/mixins/permissionsMixin"

  export default {
    name: 'region-edit-form',
    mixins: [messages, permissions],

    data() {
      return {
        formName: 'DeuEditForm',
        screenTitle: '',

        fk_region: null,
        description: null,
        description_en: null,
        address1: null,
        address2: null,
        city: null,

        modelValidations: {
          fk_region: { required: true, numeric: true, min_value: 1 },
          description: { required: true, min: 1 },
          description_en: { required: true, min: 1 },
          address1: { required: true, min: 3 },
          //address2: { required: false, min: 3 },
          city: { required: true, min: 3 },
        }
      }
    },

    props: {
      deuId: null
    },

    components: {
      Modal,
      RegionsDropdown
    },

    async mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onModalClose()
        return
      };

      if (this.deuId) {
        this.screenTitle = this.$t('route.deu_upd')
        // Load data on existing DEU
        this.loadDeu(this.deuId).then((res) => {
          const theDeu = Array.isArray(res) ? res[0] : res
          this.fk_region = theDeu.fk_region
          this.description = theDeu.description
          this.description_en = theDeu.description_en
          this.address1 = theDeu.address1
          this.address2 = theDeu.address2
          this.city = theDeu.city
        })
      } else {
        this.screenTitle = this.$t('route.deu_add')
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadDeu: 'LOAD_DEU_BY_ID',
        addDeu: 'ADD_DEU',
        editDeu: 'UPD_DEU',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      onModalClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theDeu = {
          fk_region: this.fk_region,
          description: this.description,
          description_en: this.description_en,
          address1: this.address1,
          address2: this.address2 ?? '',
          city: this.city
        };

        let errDesc = '';
        let newDeuId;
        const action = !this.deuId ? this.addDeu : this.editDeu;
        const payload = !this.deuId ? this.theDeu : { id: this.deuId, theDeu };
        try {
          const res = await action(payload)
          newDeuId = res?.deu_id;
        } catch (err) {
          errDesc = err.message
        }

        this.$nextTick(() => this.$validator.reset())
        this.onModalClose()
        await this.savedMessage(errDesc, this.$t('road_network.dep'), `${this.$t('road_network.dep')}-${this.description}`)
        this.highlightRow(newDeuId)
      }
    }
  }
</script>
<style lang='scss' scoped>
.md-button+.md-button {
  margin-left: 10px;
}
</style>