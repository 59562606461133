import {
  emptySectionInfo, emptySectionAdditionalInfo, emptySectionSurveyInfo, emptyBridgeInfo,
  emptyBridgeSurveyInfo, emptyTunnelInfo, emptyTunnelSurveyInfo
} from "@/store/helpers/tree_grid_helper"
import { numFormat } from '@/store/helpers/format_helpers'

export default {
  state: {
    mainInfo: [],
    surveyInfo: [],
    indexInfo: [],
    roadsInfo: [],
    years: [],
    //bridges_coords: [],
    vectorTypes: [
      {
        key: 'roads', value: 'route.roads',
        legend: [
          { colour: '#CC3300', name: 'road_network.class_em' },
          { colour: '#ffcc00', name: 'road_network.class_m' },
          { colour: '#339900', name: 'road_network.class_zh' }
        ]
      },
      {
        key: 'bridges',
        value: 'label.bridges',
        legend: [
          { colour: '#CC3300', name: 'bridges.soundness_imminent' },
          { colour: '#ff9966', name: 'bridges.soundness_critical' },
          { colour: '#ffcc00', name: 'bridges.soundness_poor' },
          { colour: '#99cc33', name: 'bridges.soundness_fair' },
          { colour: '#339900', name: 'bridges.soundness_good' }
        ]
      },
      { key: 'tunnels', value: 'label.tunnels', legend: [] }
    ]
  },

  data() {
    return {
      showLegendBox: true,
      ol: undefined,
    }
  },

  actions: {
    async FILL_SECTION_INFO({ dispatch, rootGetters, commit }, { section_id, deuLabel }) {
      if (!rootGetters['regionsLoaded']) {
        await dispatch('GET_REGIONS_DROPDOWN', { show_all: false })
      }
      let result = []
      let section = rootGetters['getSectionDetails'](section_id, deuLabel)
      if (section) {
        emptySectionInfo.forEach(({ name, label, isNum }) => {
          let value = section[name]
          // Fill region
          if (name === 'region_id') value = rootGetters['getRegionById'](value)
          if (isNum) value = numFormat(value, 0)

          result.push({ name, label, value })
          if (name === 'section_description') {
            commit('SET_SELECTION_INFO', value, true) //Set selected section name to show under the map
          }
        })
      }

      // Fill additional section attributes
      section = await dispatch('LOAD_SECTION_BY_ID', section_id)
      if (section) {
        emptySectionAdditionalInfo.forEach(({ name, label }) => {
          const value = section[name]
          if (value) {
            result.push({ name, label, value })
          }
        })
      }

      commit('SET_TREE_GRID_MAIN_INFO', result)
    },

    async FILL_TREE_GRID_SECTION_SURVEY_INFO({ dispatch, commit }, payload) {
      const { year, coords } = payload
      // TODO: implement filtering by year
      //year = Number(year)
      let result_survey = []
      const fnName = Array.isArray(coords) ? 'GET_CONDITION_DATA_BY_COORDS' : 'LOAD_CONDITION_BY_ID'

      const section_data = await dispatch(fnName, coords)
      if (section_data) {
        emptySectionSurveyInfo.forEach(({ name, label }) => {
          let value = section_data[name]
          if (value) {
            if (name === 'survey_date') {
              value = new Date(value).toLocaleDateString()
            }
            result_survey.push({ name, label, value })
          }
        })
      }

      commit('SET_TREE_GRID_SURVEY_INFO', result_survey)
      return 'success'
    },

    async FILL_TREE_GRID_INDEX_INFO({ commit }, year) {
      // TODO: implement in the same style
      commit('SET_TREE_GRID_INDEX_INFO', [])
      return 'success'
    },


    async FILL_BRIDGE_TUNNEL_INFO({ dispatch, commit }, { layer, uuid }) {
      let result_info = []
      let result_survey = []

      const emptyInfo = layer === 'bridge' ? emptyBridgeInfo : emptyTunnelInfo
      const emptySurveyInfo = layer === 'bridge' ? emptyBridgeSurveyInfo : emptyTunnelSurveyInfo
      const fnName = `LOAD_${layer.toUpperCase()}_DETAILS`
      const res = await dispatch(fnName, uuid)
      if (res) {
        emptyInfo.forEach(({ name, label }) => {
          const value = res[name]
          if (value) {
            result_info.push({ name, label, value })
          }
        })

        emptySurveyInfo.forEach(({ name, label }) => {
          const value = res[name]
          if (value) {
            result_survey.push({ name, label, value })
          }
        })
      }
      commit('SET_TREE_GRID_MAIN_INFO', result_info)
      commit('SET_TREE_GRID_SURVEY_INFO', result_survey)

      return 'success'
    },

    async LOAD_ROADS_GRID_INFO({ dispatch, commit }) {
      let result = [
        { id: 1, value: 'ЭМ', param: 'road_class', label: 'ЭМ Roads', children: [] },
        { id: 2, value: 'М', param: 'road_class', label: 'М Roads', children: [] },
        { id: 3, value: 'Ж', param: 'road_class', label: 'Ж Roads', children: [] },
        //{ name: '?', label: '?', children: [] }
      ]
      // Change to API call (ticket #55) when it will be ready
      await dispatch('LOAD_SECTIONS_LIST', { for_map: true }).then(() => {
        const res = this._modules.root.state.Sections.list
        res.forEach(section => {
          let index = 3
          switch (section.road_key.at(0)) {
            case 'Э': index = 0; break
            case 'М': index = 1; break
            case 'Ж': index = 2; break
            //default: index = 3
          }

          if (index < 3) {
            let i = result[index].children.findIndex(road => road.id === section.road_id)

            if (i == -1) {
              i = result[index].children.push({
                id: section.road_id,
                value: section.road,
                param: 'fk_road',
                label: 'Road',
                children: []
              }) - 1
            }
            result[index].children[i].children.push({
              id: section.section_id,
              value: section.section_description,
              param: 'section_id',
              label: 'Section',
              children: []
            })
          }
        })
      })

      /* // change to this if needed to show tree as Region/Deu/Road/Section
      await dispatch('GET_REGIONS_DROPDOWN', false).then(() => {
        const res = this._modules.root.state.Dropdowns.regions
        res.forEach(region => {
          result.push({ name: region.region_id, label: 'region', value: region.region_description, children: { value: [] } })
        })
      })
      await dispatch('LOAD_DEU_LIST', false).then(() => {
        const res = this._modules.root.state.Deu.list
        res.forEach(deu => {
          //Object.defineProperty(deu, 'children', [])
          const index = res.findIndex(({ region }) => region.region_id === res.fk_region)
          result[index].children.push({ name: deu.deu_id, label: 'deu', value: deu.deu_description, children: { value: [] } })
        })
      })
      await dispatch('LOAD_SECTIONS_LIST', {}).then(() => {
        const res = this._modules.root.state.Sections.list
      
        res.forEach(section => {
          const index = res.findIndex(({ region }) => region.region_id === res.fk_region)
          result[index].children.push({ name: section.section_id, label: 'section', value: section.section_description, children: { value: [] } })
        })
      })
      console.log('result', result)
      */
      commit('SET_ROADS_GRID_INFO', result)
    },
    /*
        async LOAD_SHOW_TYPES({ dispatch }) {
          await dispatch('LOAD_CONDITION_LIST', this.section_id).then(() => {
            const conditionList = this.$store.state.Condition.list
            if (this.emptyData) return // no data for the given section
    
          })
        },
      */
  },

  mutations: {
    SET_TREE_GRID_MAIN_INFO(state, list) {
      state.mainInfo = [...list]
    },
    SET_TREE_GRID_SURVEY_INFO(state, list) {
      state.surveyInfo = [...list]
    },
    SET_TREE_GRID_INDEX_INFO(state, list) {
      state.indexInfo = [...list]
    },
    SET_SURVEY_YEARS_LIST(state, list) {
      state.years = [...list]
    },
    SET_ROADS_GRID_INFO(state, list) {
      state.roadsInfo = [...list]
    },
    SET_TUNNELS_LEGEND(state, list) {
      //const ind = state.vectorTypes.findIndex((vec) => vec.key === 'tunnels')
      //state.vectorTypes[ind].legend = [...list]
      state.vectorTypes[2].legend = [...list]
    },
  },

  getters: {
  }
}