<!--
File: RoadsDropdown.vue
Description: show the dropdown combo with the roads list.
-->
<template>
  <md-field>
    <label for="road_id">{{ label || $t('label.select_road') }}</label>
    <md-select v-model='selectedValue' id="road_id" :disabled="disabled" :required="is_required" @md-selected='onChange'
      size="10">
      <md-option v-for='item in roadsList' :key='item.id' :value='item.id'>
        {{ item.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    name: 'roads-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: Number },
      items: { default: () => [], type: Array },
      region_id: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,
        listLoaded: false
      }
    },

    methods: {
      onChange() {
        this.$emit('input', this.selectedValue, this.getRoadById)
      },
    },

    computed: {
      ...mapState({
        roads: (state) => state.Dropdowns.roads
      }),

      itemsProvided() {
        return this.items.length > 0;
      },

      /*itemsFilterd(){
        return this.items.filter(item => item.id);
      },
*/
      roadsList() {
        return this.itemsProvided ? this.items : this.roads;
      },

      getRoadById() {
        return this.roadsList.find(el => el.id == this.selectedValue)?.description || ''
      }
    },

    watch: {
      region_id(newValue) {
        if (!this.itemsProvided) {
          this.listLoaded = false
          //this.selectedValue = null
          this.$store.dispatch('GET_ROADS_DROPDOWN', { region_id: newValue, show_all: !this.is_required }).then(() => {
            this.listLoaded = true
            if (this.value && !this.selectedValue) this.selectedValue = this.value;
          })
        } else {
          this.listLoaded = true
        }
      },

      value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.selectedValue = newValue;
        }
      }
    }
  }
</script>