//listViewMixin.js

import { mapState, mapGetters, mapActions } from 'vuex'
import { onClose } from '@/mixins/onCloseMixin'
import messages from '@/mixins/messagesMixin'

export default {
  mixins: [messages],

  methods: {
    ...mapActions({
      loadHistory: 'LOAD_HISTORY',
      saveHistory: 'SAVE_HISTORY',
      setHistory: 'USE_HISTORY',
      clrHistory: 'CLEAR_HISTORY',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    onClose,

    clearHistory() {
      // Set the timer for highlighting for 5 sec
      if (this.highlightedRow) setTimeout(() => { this.clrHistory() }, 5000);
    },

    customSort(value, secondarySort, tertiarySort) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort

        // Сортировка по первичному полю
        let res = (a[sortBy] && b[sortBy])
          ? a[sortBy].toString().localeCompare(b[sortBy].toString(), undefined, { numeric: true })
          : (a[sortBy] ? 1 : -1);

        // Если первичное поле равное, сортируем по вторичному полю
        if (secondarySort && res === 0) {
          res = (a[secondarySort] && b[secondarySort])
            ? a[secondarySort].toString().localeCompare(b[secondarySort].toString(), undefined, { numeric: true })
            : (a[secondarySort] ? 1 : -1);
        }

        if (tertiarySort && res === 0) {
          res = (a[tertiarySort] && b[tertiarySort])
            ? a[tertiarySort].toString().localeCompare(b[tertiarySort].toString(), undefined, { numeric: true })
            : (a[tertiarySort] ? 1 : -1);
        }

        // Возвращаем результат с учетом направления сортировки
        return (this.currentSortOrder === 'asc') ? res : -res
      })
    },

    scrollToHighlightedRow() {
      this.$nextTick(() => {
        const highlightedRowElement = document.getElementById('highlightedRow');
        if (highlightedRowElement) {
          highlightedRowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },

    async deleteItem(id, descr) {
      this.save_history()
      const { value } = await this.deleteConfirmation(descr)
      if (!value) {
        return
      }

      this.delete(id).then(
        () => {
          this.reloadData()
          this.deletedMessage(descr)
        },
        (err) => {
          this.errorMessage(err.message)
        }
      )
    }
  },

  computed: {
    ...mapState({
    }),

    ...mapGetters([]),

    queriedData() {
      const result = this.searchedData
      return result.slice(this.from, this.to)
    },

    rowsCount() {
      return this.tableData.length
    },

    searchedData() {
      if (!this.rowsCount) {
        return []
      } else {
        return (!this.searchQuery || !this.fuseSearch) ? this.tableData : this.fuseSearch.search(this.searchQuery)
      }
    },

    total() {
      return this.searchedData.length
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
  }
}