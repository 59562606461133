<!--
File: YearsDropdown.vue
Description: show the dropdown combo with the years list that are exist in the RDB.
-->
<template>
  <md-field>
    <label for="year">{{ label || $t('budget.select_year') }}</label>
    <md-select v-model="selectedValue" id="year" :disabled="disabled" @md-selected="onChange">
      <md-option v-for='(item, ind) in yearsList' :key='ind' :value='item.id'>
        {{ item.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
export default {
  name: 'years-dropdown',

  props: {
    label: { default: null, type: String },
    value: { default: null, type: Number },
    disabled: { default: false, type: Boolean },
    items: { default: () => [], type: Array },
    valueField: { default: "survey_year", type: String },
  },

  data() {
    return {
      selectedValue: this.value || null,
      listLoaded: false
    }
  },

  mounted() {
    if (this.itemsProvided) {
      this.listLoaded = true
    } else {
      this.$store.dispatch('GET_YEARS_LIST').then(() => {
        this.listLoaded = true
        if (this.value && !this.selectedValue) this.selectedValue = this.value
      })
    }
  },

  methods: {
    onChange() {
      this.$emit('input', this.selectedValue)
    },
  },

  computed: {
    years() {
      return this.$store.state.Dropdowns.years;
    },

    itemsProvided() {
      return this.items.length > 0;
    },

    yearsList() {
      return this.itemsProvided ? this.items : this.years;
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue && this.listLoaded) {
        this.selectedValue = newValue;
      }
    },
  }
}
</script>