<!--
File: ThresholdVariableDropdown.vue
Description: show the dropdown combo with the threshold variables dropdown list .
-->
<template>
  <md-field>
    <label for="key">{{ label }}</label>
    <md-select v-model='selectedValue' id="key" :disabled="disabled" :required="is_required" @md-selected='onChange'>
      <md-option v-for='(tr, ind) in variables' :key='ind' :value='tr.key'>
        {{ tr.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'threshold-variables-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: String },   // type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,
        listLoaded: false
      }
    },

    mounted() {
      this.$store.dispatch('GET_THRESHOLD_VARIABLES_DROPDOWN', this.is_required).then(() => {
        this.listLoaded = true
        if (this.value && !this.selectedValue) this.selectedValue = this.value
      })
    },

    methods: {
      onChange() {
        const desc = this.variables.find((el) => el.key === this.selectedValue)?.description || ''
        this.$emit('input', this.selectedValue, desc)
      },
    },

    computed: {
      variables() {
        return this.$store.state.Treatments.variablesList;
      }
    },

    watch: {
      value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.selectedValue = newValue
        }
      },
    }
  }
</script>