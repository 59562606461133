/*
File: api/rbac.js
Description: API connections for the RBAC implementation.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
    /*--------- ROLES ---------------------*/
  load_roles_list() {
    return Api.get(`/rbac/role/all${getLangQuery()}`, getHeaders());
  },

  get_role_by_id(id) {
    return Api.get(`/rbac/role/${id}${getLangQuery()}`, getHeaders());
  },

  add_role(role) {
    return Api.post(`/rbac/role`, role, getHeaders());
  },

  upd_role(id, role) {
    return Api.put(`/rbac/role/${id}`, role, getHeaders());
  },

  del_role(id) {
    return Api.delete(`/rbac/role/${id}`, getHeaders());
  },

  /*--------- PERMISSIONS ---------------------*/
  load_permissions_pivot() {
    return Api.get('/rbac/permission/permission_role_pivot', getHeaders());
  },

  get_permissions_anonymous() {
    return Api.get('/rbac/permission/get_front_permissions_anonymous', getHeaders());
  },

  get_permissions_by_user(user_id) {
    return Api.get(`/rbac/permission/get_front_permissions_by_user_id/${user_id}`, getHeaders());
  },

  /*--------- USER ROLES ---------------------*/
  load_user_roles(role_id, user_id) {
    return Api.get(`/rbac/user_role/all${getLangQuery()}`, getHeaders());
  },

  get_user_role_by_id(user_role_id) {
    return Api.get(`/rbac/user_role/${user_role_id}${getLangQuery()}`, getHeaders());
  },

  add_user_role(user_role) {
    return Api.post('/rbac/user_role', user_role, getHeaders());
  },

  del_user_role(user_role_id) {
    return Api.delete(`/rbac/user_role/${user_role_id}`, getHeaders());
  },
  
  /*--------- API PATH ---------------------*/
  load_api_path_list() {
    return Api.get(`/rbac/api_path/all${getLangQuery()}`, getHeaders());
  },

  get_api_path_by_id(api_path_id) {
    return Api.get(`/rbac/api_path/${api_path_id}${getLangQuery()}`, getHeaders());
  },

  get_api_url_map() {
    return Api.get(`/rbac/api_path/api_url_map${getLangQuery()}`, getHeaders());
  },

  add_api_path(api_path) {
    return Api.post('/rbac/api_path', api_path, getHeaders());
  },

  upd_api_path(api_path_id, api_path) {
    return Api.put(`/rbac/role/${api_path_id}`, api_path, getHeaders());
  },

  sync_api_path() {
    return Api.post('/rbac/api_path/synchronize_to_db', getHeaders());
  },

  del_api_path(api_path_id) {
    return Api.delete(`/rbac/api_path/${api_path_id}`, getHeaders());
  },

};
