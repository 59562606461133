import Api from '@/api/planning'
import { apiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: [],
    dropdownList: []
  },

  actions: {
    async LOAD_PLAN_LIST({ commit }, payload) {
      const { region_id, year, forDropdown = false } = payload
      const data = await apiCall(Api.load_plan_list(region_id, year), 'LOAD_PLAN_LIST');

      data.sort((a, b) => {
        let res = a.region_description.localeCompare(b.region_description, undefined, { sensitivity: 'base' })
        if (res == 0) { res = a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) }
        return res
      })
      commit(forDropdown ? 'SET_PLAN_DROPDOWN_LIST' : 'SET_PLAN_LIST', data)
    },

    async LOAD_PLAN_BY_ID({ commit }, id) {
      return await apiCall(Api.load_plan_by_id(id), 'LOAD_PLAN_BY_ID');
    },

    async ADD_PLAN({ commit }, payload) {
      return await apiCall(Api.add_plan(payload), 'ADD_PLAN', 201);
    },

    async UPD_PLAN({ commit }, { id, plan }) {
      return await apiCall(Api.upd_plan(id, plan), 'UPD_PLAN', 201);
    },

    async APPROVE_PLAN({ commit }, id) {   //payload) {
      return await apiCall(Api.approve_plan(id), 'APPROVE_PLAN', 201);
    },

    async CANCEL_PLAN({ commit }, id) {
      return await apiCall(Api.cancel_plan(id), 'CANCEL_PLAN', 201);
    },

    async DELETE_PLAN({ commit }, id) {
      return await apiCall(Api.delete_plan(id), 'DELETE_PLAN');
    },

    async PLAN_CHANGE_LOCK_STATUS({ commit }, id) {
      return await apiCall(Api.plan_change_lock_status(id), 'PLAN_CHANGE_LOCK_STATUS');
      // return Boolean(res.data.locked_at)
    }
  },

  mutations: {
    SET_PLAN_LIST(state, list) {
      state.list = [...list]
    },
    SET_PLAN_DROPDOWN_LIST(state, list) {
      state.dropdownList = [...list]
    },
  },

  getters: {
    filterAndSortPlans: (state) => (fk_region, year, isApproved, dropDown = true) => {
      const res = dropDown ? state.dropdownList : state.list
      return res.filter(el =>
        (isApproved ? el.approved_by_user_id : !el.approved_by_user_id) &&  // Check if approved/not approved
        (!fk_region || el.fk_region === fk_region) &&                       // Filter by region (if provided)
        (!year || el.year === year)                                         // Filter by year (if provided)
      ).map(el => ({ id: el.id, name: el.name }))     // Get only necessary fields
        // Order by name
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    },

    plansApproved: (state, getters) => (fk_region, year, dropDown = true) => {
      return getters.filterAndSortPlans(fk_region, year, true, dropDown);
    },

    plansNotApproved: (state, getters) => (fk_region, year, dropDown = true) => {
      return getters.filterAndSortPlans(fk_region, year, false, dropDown);
    },

    planApproved: (state) => (id) => {
      return Boolean(state.list.find(el => el.id == id)?.approved_by_user_id)
    },

    findPlanByName: (state) => (planName) => {
      return state.dropdownList.find(el => el.name === planName)?.id
    },

    yearsInPlanList: (state) => {
      // Get all unique years in the list, order and return as an array of objects for YearsDropdown
      const uniqueYears = [...new Set(state.list.reduce((acc, item) => {
        if (item.year !== null) acc.push(item.year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => ({ id: year, description: year }));

      uniqueYears.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      return uniqueYears
    },

    planListByYear: (state) => (year) => {
      return !year ? state.list : state.list.filter(row => row.year === year)
    },
  }
}