<!--
File: DepsDropdown.vue
Description: show the dropdown combo with the DEU list .
-->
<template>
  <md-field>
    <label for="deu_id">{{ label || $t('label.select_deu') }}</label>
    <md-select v-model='selectedValue' id="deu_id" :disabled="disabled" :required="is_required" @md-selected="onChange">
      <md-option v-for="item in deuList" :key="item.id" :value="item.id">
        {{ item.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'deus-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      regionId: { default: null, type: Number },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,
        listLoaded: false
      };
    },

    methods: {
      onChange() {
        this.$emit('input', this.selectedValue, this.getDeuById(this.selectedValue))
      }
    },

    computed: {
      ...mapState({
        deuList: (state) => state.Dropdowns.deus
      }),
      ...mapGetters(['getDeuById']),
    },

    watch: {
      regionId(newValue) {
        this.listLoaded = false
        this.selectedValue = null     // To reset
        const params = { region_id: newValue, show_all: !this.is_required, prefix: this.$t('road_network.dep') }
        this.$store.dispatch('GET_DEUS_DROPDOWN', params).then(() => {
          this.listLoaded = true     // Устанавливаем флаг, когда deuList загружен
          if (this.value && !this.selectedValue) this.selectedValue = this.value;
        })
      },

      value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.selectedValue = newValue;
        }
      }
    },

  }
</script>