<!--
File: StyleTypesDropdown.vue
Description: show the dropdown combo with the style types llist (circe/line) for showing in the map.
-->
<template>
  <md-field>
    <label for="styleType">{{ label }}</label>
    <md-select v-model='selectedValue' id="styleType" :disabled="disabled" :required="is_required" @md-selected='onChange'>
      <md-option v-for="styleType of styleTypes" :key="styleType.key" :value="styleType.key">
        {{ $t(styleType.value) }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'style-types-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: String },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        styleTypes: [{ key: 'line', value: "line" }, { key: 'point', value: "point" }],
        selectedValue: this.value || null,
        listLoaded: false,
      }
    },

    mounted() {
      if (this.value && !this.selectedValue) this.selectedValue = this.value
      this.listLoaded = true
    },

    methods: {
      onChange() {
        this.$emit('input', this.selectedValue)
      },
    },

    watch: {
      value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.selectedValue = newValue
        }
      },
    }
  }
</script>