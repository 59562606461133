<!--
File: ConditionIndexCriteriaSingle.vue
Description: show list/pivot of Condition Index Criteria.
-->
<template>
  <md-dialog :md-active.sync="show" :md-click-outside-to-close="false">
    <md-dialog-title>{{ $t('label.edit_critera') }}
      <md-button class='md-simple md-just-icon md-round modal-default-button' @click='onModalClose'>
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <md-dialog-content>
      <div>
        <BaseDropdown
          :class="[{ 'md-valid': !errors.has('item.indicator_type') }, { 'md-error': errors.has('item.indicator_type') }]"
          :label="$t('label.indicator_type')" v-model="item.indicator_type" :items="dropDownList"
          :displayField="'description'" :valueField="'id'" :is_required="true" />
      </div>
      <BaseDropdown :class="[{ 'md-valid': !errors.has('item.score') }, { 'md-error': errors.has('item.score') }]"
        :label="$t('label.score')" v-model="item.score" :items="getScores" :displayField="'val'" :valueField="'val'"
        :is_required="true" />
      <FloatInput :class="[{ 'md-valid': !errors.has('item.value_from') }, { 'md-error': errors.has('item.value_from') }]"
        :label="$t('label.value_from')" v-model="item.value_from" data-vv-name="value_from"
        v-validate="modelValidations.value_from" required />
      <FloatInput :class="[{ 'md-valid': !errors.has('item.value_to') }, { 'md-error': errors.has('item.value_to') }]"
        :label="$t('label.value_to')" v-model="item.value_to" data-vv-name="value_to" :step="0.1"
        v-validate="modelValidations.value_to" required />
    </md-dialog-content>

    <span v-if="errors.count() > 0" class="md-error">
      {{ $t('messages.errors_count', { count: errors.count() }) }}
    </span>
    <md-dialog-actions>
      <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
        {{ $t('buttons.save') }}
      </md-button>
      <md-button class="md-primary" @click='onModalClose'>{{ $t('buttons.close') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import { onModalClose } from '@/mixins/onCloseMixin'
  import BaseDropdown from '@/pages/Components/BaseDropdown.vue'
  import FloatInput from '@/pages/Components/FloatInput.vue'
  import messagesMixin from '@/mixins/messagesMixin'

  const emptyItem = () => {
    return {
      indicator_type: null,
      score: null,
      value_from: null,
      value_to: null
    }
  }

  export default {
    name: 'condition-index-criteria-single',
    mixins: [messagesMixin],

    props: {
      show: false,
      itemId: { type: Number, default: 0 },
    },

    data() {
      return {
        item: emptyItem(),

        modelValidations: {
          indicator_type: { required: true },
          score: { required: true, numeric: true },
          value_from: { required: true, decimal: true },
          value_to: { required: true, decimal: true },
        }
      }
    },

    components: {
      BaseDropdown,
      FloatInput
    },

    methods: {
      onModalClose,

      async reloadData(value) {
        this.item = !value ? emptyItem() : await this.$store.dispatch('LOAD_CONDITION_INDEX_CRITERIA_BY_ID', value)
        this.$nextTick(() => {
          this.$validator.validateAll()
        })
      },

      async validate() {
        const isValid = await this.$validator.validateAll();
        if (!isValid) return;

        const payload = !this.itemId ? this.item : { id: this.itemId, condition: this.item }
        const action = `${!this.itemId ? 'ADD' : 'UPD'}_CONDITION_INDEX_CRITERIA`;
        let errDesc = '';
        try {
          await this.$store.dispatch(action, payload);
        } catch (err) {
          errDesc = err.message;
        }
        const descr = this.$t(`condition.${this.item.indicator_type}`) + '-' + this.$t(`label.score_${this.item.score}`)
        this.savedMessage(errDesc, this.$t('condition.condition_index'), descr);

        this.$nextTick(() => this.$validator.reset())
        this.onModalClose();
      }
    },

    computed: {
      ...mapState({
        //criteriaList: (state) => state.ConditionIndexCriteria.list,
      }),
      ...mapGetters(['amIAdmin', 'getDropDownList', 'getScores']),

      dropDownList() {
        return this.getDropDownList.map(item => ({ ...item, description: this.$t(item.description) }))
      },

      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    },

    watch: {
      itemId(value) {
        this.reloadData(value)
      }
    }
  }
</script>