<!--
File: RegionsDropdown.vue
Description: show the dropdown combo with the regions list .
-->
<template>
  <md-field>
    <label for="region_id">{{ label || $t('label.select_region') }}</label>
    <md-select v-model='selectedValue' id="region_id" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for='item in regions' :key='item.id' :value='item.id'>
        {{ item.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'regions-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,
        listLoaded: false
      }
    },

    mounted() {
      this.$store.dispatch('GET_REGIONS_DROPDOWN', { show_all: !this.is_required }).then(() => {
        this.listLoaded = true
        if (this.value && !this.selectedValue) this.selectedValue = this.value;
      })
    },

    methods: {
      onChange() {
        this.$emit('input', this.selectedValue, this.getRegionById(this.selectedValue))
      },
    },

    computed: {
      ...mapState({
        regions: (state) => state.Dropdowns.regions
      }),
      ...mapGetters(['getRegionById']),
    },

    watch: {
      value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.selectedValue = newValue;
        }
      },
    }
  }
</script>