/* 
File: traffic.js
Description: provides routines for traffic sites and traffic installationforms, calls API's.
*/
import Api from '@/api/traffic';
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    trafficSitesList: [],
    trafficInstallationsList: []
  },

  actions: {
    /*** Traffic Sites  ***/
    async LOAD_TRAFFIC_SITES({ commit }) {
      return await handleApiCall({
        request: Api.load_traffic_site_all(),
        caller: 'LOAD_TRAFFIC_SITES',
        commit, mutation: 'SET_TRAFFIC_SITES'
      });
    },

    async LOAD_TRAFFIC_SITE_BY_ID({ commit }, id) {
      return await apiCall(Api.load_traffic_site_by_id(id), 'LOAD_TRAFFIC_SITE_BY_ID');
    },

    async ADD_TRAFFIC_SITE({ commit }, traffic_site) {
      return await apiCall(Api.add_traffic_site(traffic_site), 'ADD_TRAFFIC_SITE', 201);
    },

    async UPD_TRAFFIC_SITE({ commit }, { id, traffic_site }) {
      return await apiCall(Api.upd_traffic_site(id, traffic_site), 'UPD_TRAFFIC_SITE', 201);
    },

    async DEL_TRAFFIC_SITE({ commit }, traffic_site_id) {
      return await apiCall(Api.del_traffic_site(traffic_site_id), 'DEL_TRAFFIC_SITE');
    },

    /*** Traffic Installations  ***/
    async LOAD_TRAFFIC_INSTALLATION({ commit }) {
      return await handleApiCall({
        request: Api.load_traffic_installation_all(),
        caller: 'LOAD_TRAFFIC_INSTALLATION',
        commit, mutation: 'SET_TRAFFIC_INSTALLATION'
      });
    },

    async LOAD_TRAFFIC_BY_ID({ commit }, id) {
      return await apiCall(Api.load_traffic_installation_by_id(id), 'LOAD_TRAFFIC_BY_ID');
    },

    async ADD_TRAFFIC_INSTALLATION({ commit }, traffic) {
      return await apiCall(Api.add_traffic_installation(traffic), 'ADD_TRAFFIC_INSTALLATION', 201);
    },

    async UPD_TRAFFIC_INSTALLATION({ commit }, { id, theTraffic }) {
      return await apiCall(Api.upd_traffic_installation(id, theTraffic), 'UPD_TRAFFIC_INSTALLATION', 201);
    },
  },

  mutations: {
    SET_TRAFFIC_SITES(state, list) {
      state.trafficSitesList = [...list];
    },
    SET_TRAFFIC_INSTALLATION(state, list) {
      state.trafficInstallationsList = [...list];
    },
  },

  getters: {
    roadsInTrafficSitesList: (state) => {
      // Get all unique years in the list, order and return as an array of objects for RoadsDropdown
      const uniqueIds = new Set();
      const res = state.trafficSitesList.reduce((acc, item) => {
        if (!uniqueIds.has(item.fk_road)) {
          uniqueIds.add(item.fk_road);
          acc.push({ id: item.fk_road, description: item.road });
        }
        return acc;
      }, [])
        .sort((a, b) => !a.description ? 0 : a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))

      res.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      return res
    },

    trafficSitesFiltered: (state) => (fk_road) => {
      return state.trafficSitesList.filter(item => {
        return !fk_road || item.fk_road == fk_road
      })
    }
  }
};
