<!--
File: Report_1_AdministrativeSetup.vue
Description: produce the list of DEUs, and Regions under RMD for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown v-model="selectedRegion" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selectedDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
          <md-button class="md-raised md-success" @click="exportToExcel" :disabled="btnDisabled">
            {{ $t('buttons.excel_export') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class='"paginated-table table-striped table-hover' :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell style="width:20%" :md-label="$t('road_network.region')" md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell style="width:10%" :md-label="$t('road_network.dep')" md-sort-by="deu_description">
            <a href='#' @click.stop.prevent='showSections(item)'> {{ item.deu_description }} </a>
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('road_network.length_km_road_class_em')"
            md-sort-by="length_km_road_class_em" md-numeric>
            {{ item.length_km_road_class_em | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('road_network.length_km_road_class_m')"
            md-sort-by="length_km_road_class_m" md-numeric>
            {{ item.length_km_road_class_m | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:15%" :md-label="$t('road_network.length_km_road_class_zh')"
            md-sort-by="length_km_road_class_zh" md-numeric>
            {{ item.length_km_road_class_zh | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-table>
        <md-table-row>
          <md-table-head style="width:20%">{{ $t('label.total') }}</md-table-head>
          <md-table-head style="width:10%">{{ report_1_total.total }}</md-table-head>
          <md-table-head style="width:20%" md-numeric>{{ report_1_total.length_km_road_class_em | numFormat
          }}</md-table-head>
          <md-table-head style="width:20%" md-numeric>{{ report_1_total.length_km_road_class_m | numFormat
          }}</md-table-head>
          <md-table-head style="width:15%" md-numeric>{{ report_1_total.length_km_road_class_zh | numFormat
          }}</md-table-head>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
  import { RegionsDropdown } from '@/pages/Components'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { numFormat } from "@/store/helpers/format_helpers"
  import { customSortMixin } from '@/mixins/customSortMixin'
  import printReport from '@/mixins/printMixin'
  import ExcelJS from 'exceljs';
  import { saveAs } from 'file-saver'; // Для сохранения файла на клиенте

  export default {
    name: 'roads-administrative-setup',
    mixins: [customSortMixin, printReport],
    data() {
      return {
        selectedRegion: null,
        selectedDate: new Date(),
        btnDisabled: true,

        showSpinner: false,
        currentSort: 'region_description',
        currentSortOrder: 'asc'
      }
    },

    components: {
      RegionsDropdown
    },

    mounted() {
    },

    methods: {
      ...mapActions({
        loadReportData: 'REPORT_1_ADMINISTRATIVE_SETUP',
        saveHistory: 'SAVE_HISTORY'
      }),

      reloadData() {
        this.showSpinner = true
        this.btnDisabled = true
        const params = { inserted_date: this.selectedDate.toISOString().substring(0, 10), region_id: this.selectedRegion }
        this.loadReportData(params).then(() => {
          this.btnDisabled = false
          this.showSpinner = false
        })
      },

      print() {
        this.showSpinner = true
        this.printReport(this.getPrintHeader, this.getTableHeaders, this.getTableRows)
        this.showSpinner = false
      },

      showSections(item) {
        const hist = {
          form: 'sections',
          data: {
            selectedRegion: item.region_id,
            selectedDeu: item.deu_id,
            perPage: 20,
            currentPage: 1
          },
          use: true,
          closePath: window.location.pathname  // current URL path
        }
        this.saveHistory(hist)
        this.$router.push('/inventory_data/sections')
      },

      setWorkSheetHeader(worksheet) {
        let titleRow = worksheet.insertRow(1, [this.$t('label.reports_title')])
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:E1');

        const today = new Date().toLocaleDateString()
        titleRow = worksheet.insertRow(2, [
          `${this.$t('label.report1_title')} ${this.$t('label.as_on')} ${today}`
        ]);
        titleRow.font = { size: 14 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A2:E2');

        if (this.headerTitles) {
          titleRow = worksheet.insertRow(3, [this.headerTitles]);
          titleRow.font = { size: 14 };
          titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.mergeCells('A3:E3');
        }
      },

      fillWorkSheet(workbook, workbookName) {
        const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
        const worksheet = workbook.addWorksheet(workbookName);

        // Set the table headers
        const columns = this.getWorksheetColumns
        worksheet.columns = columns

        // Apply formatting to the table headers first  row
        let newRow = worksheet.getRow(1);
        newRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }
        newRow.fill = headerCellFill
        newRow.alignment = { vertical: 'middle', horizontal: 'center' }

        // Number formatting for the columns
        columns.forEach(item => {
          if (item.num) worksheet.getColumn(item.key).numFmt = '#,##0.00'
        })

        this.report.forEach(item => {
          worksheet.addRow(item);
        });

        // Show the total
        const totalRow = worksheet.addRow([
          this.$t('label.total'),
          this.report_1_total.total,
          numFormat(this.report_1_total.length_km_road_class_em),
          numFormat(this.report_1_total.length_km_road_class_m),
          numFormat(this.report_1_total.length_km_road_class_zh)
        ])
        totalRow.font = { bold: true, size: 12 }

        // Set the worksheet header, should be after filling the table
        this.setWorkSheetHeader(worksheet)
      },

      async exportToExcel() {
        // Create new Excel file
        const workbook = new ExcelJS.Workbook();
        const wbName = this.$t('route.report_roads_administrative_setup')
        this.fillWorkSheet(workbook, wbName)

        // Save
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${wbName}.xlsx`);
      },
    },

    computed: {
      ...mapState({
        report_1_data: (state) => state.Reports.report_1_data,
      }),

      ...mapGetters(['report_1_total']),

      report() {
        return this.customSort(this.report_1_data
          .map(item => { return { ...item, deu_description: `${this.$t('road_network.dep')}-${item.deu_description}` } }),
          'deu_description')
      },

      total() {
        return this.report.length
      },

      getWorksheetColumns() {
        return [
          { header: this.$t('road_network.region'), key: 'region_description', width: 30, group: true },
          { header: this.$t('road_network.dep'), key: 'deu_description', width: 10 },
          { header: this.$t('road_network.length_km_road_class_em'), key: 'length_km_road_class_em', width: 10, num: true },
          { header: this.$t('road_network.length_km_road_class_m'), key: 'length_km_road_class_m', width: 10, num: true },
          { header: this.$t('road_network.length_km_road_class_zh'), key: 'length_km_road_class_zh', width: 10, num: true },
        ];
      },

      headerTitles() {
        return this.selectedRegion ? `(${this.$t('label.from_region', { name: this.report[0].region_description })})` : ''
      },

      getPrintHeader() {
        const today = new Date().toLocaleDateString()
        const regionDesc = this.headerTitles

        return `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
            <h4 style='text-align:center'>${this.$t('label.report1_title')} ${this.$t('label.as_on')}${today} ${regionDesc}</h4>`;
      },

      getTableRows() {
        const columns = this.getWorksheetColumns

        const tableRowsArray = this.report.map(item => {
          return `<tr>${columns.map(col => col.num
            ? `<td class='numCell'>${numFormat(item[col.key])}</td>`
            : `<td>${item[col.key]}</td>`).join('')}
        </tr>`;
        });

        // Добавляем итоговую строку в массив строк
        tableRowsArray.push(`<tr>
        <th>${this.$t('label.total')}</th>
            <th>${this.report_1_total.total}</th>
            <th class='numCell'>${numFormat(this.report_1_total.length_km_road_class_em)}</th>
            <th class='numCell'>${numFormat(this.report_1_total.length_km_road_class_m)}</th>
            <th class='numCell'>${numFormat(this.report_1_total.length_km_road_class_zh)}</th>
      </tr>`
        );

        // Преобразуем массив строк таблицы в строку
        return tableRowsArray.join('');
      },
    },

    watch: {
      selectedDate(value) {
        if (!value) this.selectedDate = new Date()
        else this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.md-table-cell.md-numeric {
  padding: 0px !important;
  padding-right: 18px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

thead {
  display: table-header-group;
}

tfoot {
  display: table-footer-group;
}
</style>