/* 
File: reports.js
Description: Async functions for  Reports .
*/
import Api from '@/api/reports'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    report_1_data: [],    // administrative_setup
    report_2_data: [],    // list_of_roads
    report_3_data: [],    // yearwise_report
    report_4_data: [],    // length_by_traffic
    report_5_data: [],    // road_way_details
    report_6_data: [],    // road_sections
    report_7_data: [],    // road_condition_summary
    report_8_data: [],    // road_condition_inxex
    report_9_data: [],    // yearly_condition_inxex
    report_10_data: [],   // section_wise_aadt
    report_11_data: [],   // total_elevation_zones
    report_12_data: [],   // road_length_by_elevation_zones
  },

  actions: {
    async REPORT_1_ADMINISTRATIVE_SETUP({ commit }, { inserted_date, region_id }) {
      return await handleApiCall({
        request: Api.report_1_administrative_setup(inserted_date, region_id),
        caller: 'REPORT_1_ADMINISTRATIVE_SETUP',
        commit, mutation: 'SET_REPORT_1'
      });
    },

    async REPORT_2_ROAD_INFO({ commit }, values) {
      const { inserted_date, region_id, deu_id, road_class } = values
      return await handleApiCall({
        request: Api.report_2_roads_info(inserted_date, region_id, deu_id, road_class),
        caller: 'REPORT_2_ROAD_INFO',
        commit, mutation: 'SET_REPORT_2'
      });
    },

    async REPORT_3_YEARWISE_LENGTH({ commit }, values) {
      const { start_year, end_year, region_id, road_class, deu_id } = values
      return await handleApiCall({
        request: Api.report_3_year_wise_road_length(start_year, end_year, region_id, road_class, deu_id),
        caller: 'REPORT_3_YEARWISE_LENGTH',
        commit, mutation: 'SET_REPORT_3'
      });
    },

    async REPORT_4_LENGTH_BY_TRAFFIC({ commit }, values) {
      const { start_year, end_year, region_id, road_class, deu_id } = values
      return await handleApiCall({
        request: Api.report_4_road_length_by_traffic(start_year, end_year, region_id, road_class, deu_id),
        caller: 'REPORT_4_LENGTH_BY_TRAFFIC',
        commit, mutation: 'SET_REPORT_4'
      });
    },

    async REPORT_5_ROADWAY_DETAILS({ commit }, values) {
      const { region_id, road_class, deu_id, selected_date } = values
      return await handleApiCall({
        request: Api.report_5_road_way_details(region_id, road_class, deu_id, selected_date),
        caller: 'REPORT_5_ROADWAY_DETAILS',
        commit, mutation: 'SET_REPORT_5'
      });
    },

    async REPORT_6_ROAD_SECTIONS({ commit }, values) {
      const { region_id, road_class, deu_id, inserted_date } = values
      return await handleApiCall({
        request: Api.report_6_sections(region_id, road_class, deu_id, inserted_date),
        caller: 'REPORT_6_ROAD_SECTIONS',
        commit, mutation: 'SET_REPORT_6'
      });
    },

    async REPORT_7_ROAD_CONDITION_DATA({ commit }, values) {
      const { region_id, deu_id, road_id, section_id, start_date, end_date } = values
      return await handleApiCall({
        request: Api.report_7_road_condition_summary(region_id, deu_id, road_id, section_id, start_date, end_date),
        caller: 'REPORT_7_ROAD_CONDITION_DATA',
        commit, mutation: 'SET_REPORT_7'
      });
    },

    async REPORT_8_ROAD_CONDITION_INDEX({ commit }, values) {
      const { region_id, survey_year } = values
      return await handleApiCall({
        request: Api.report_8_region_wise_condition(region_id, survey_year),
        caller: 'REPORT_8_ROAD_CONDITION_INDEX',
        commit, mutation: 'SET_REPORT_8'
      });
    },

    async REPORT_9_YEARLY_CONDITION_INDEX({ commit }, values) {
      const { region_id, deu_id, road_id, section_id, start_year, end_year } = values
      return await handleApiCall({
        request: Api.report_9_yearly_condition(region_id, deu_id, road_id, section_id, start_year, end_year),
        caller: 'REPORT_9_YEARLY_CONDITION_INDEX',
        commit, mutation: 'SET_REPORT_9'
      });
    },

    async REPORT_10_SECTION_WISE_AADT({ commit }) {
      //const { selected_year, region_id, deu_id, road_id } = values
      return await handleApiCall({
        request: Api.report_10_section_wise_aadt(), // selected_year, selected_year, region_id, deu_id, road_id),
        caller: 'REPORT_4_LENREPORT_10_SECTION_WISE_AADTGTH_BY_TRAFFIC',
        commit, mutation: 'SET_REPORT_10'
      });
    },

    async REPORT_11_TOTAL_ELEVATION_ZONES({ commit }) {
      const data = await apiCall(Api.report_11_total_elevation_zones(), 'REPORT_11_TOTAL_ELEVATION_ZONES')

      // remove next 5 lines after implementing via API
      const lang = global.localStorage["mps-locale"]
      const res = data.map(item => ({
        ...item,
        region_description: item[lang === 'ru' ? 'region_description' : 'region_description_en']
      }))

      commit('SET_REPORT_11', res)
      return 'success'
    },

    async REPORT_12_LENGTH_BY_ELEVATION_ZONES({ commit }) {
      const data = await apiCall(Api.report_12_road_length_by_elevation_zones(), 'REPORT_12_LENGTH_BY_ELEVATION_ZONES')

      // remove next 5 lines after implementing via API
      const lang = global.localStorage["mps-locale"]
      const res = data.map(item => ({
        ...item,
        region_description: item[lang === 'ru' ? 'region_description' : 'region_description_en']
      }))

      commit('SET_REPORT_12', res)
      return 'success'
    },
  },

  mutations: {
    SET_REPORT_1(state, list) {
      state.report_1_data = [...list]
    },
    SET_REPORT_2(state, list) {
      state.report_2_data = [...list]
    },
    SET_REPORT_3(state, list) {
      state.report_3_data = [...list]
    },
    SET_REPORT_4(state, list) {
      state.report_4_data = [...list]
    },
    SET_REPORT_5(state, list) {
      state.report_5_data = [...list]
    },
    SET_REPORT_6(state, list) {
      state.report_6_data = [...list]
    },
    SET_REPORT_7(state, list) {
      state.report_7_data = [...list]
    },
    SET_REPORT_8(state, list) {
      state.report_8_data = [...list]
    },
    SET_REPORT_9(state, list) {
      state.report_9_data = [...list]
    },
    SET_REPORT_10(state, list) {
      state.report_10_data = [...list]
    },
    SET_REPORT_11(state, list) {
      state.report_11_data = [...list]
    },
    SET_REPORT_12(state, list) {
      state.report_12_data = [...list]
    }
  },

  getters: {
    report_1_total: (state) => {
      const fields = [
        'length_km_road_class_m',
        'length_km_road_class_em',
        'length_km_road_class_zh',
        'road_class_none'
      ];

      const res = fields.reduce((totals, field) => {
        totals[field] = state.report_1_data.reduce((acc, currVal) => acc + (currVal[field] || 0), 0);
        return totals;
      }, {});
      res['total'] = state.report_1_data.length
      return res
    },

    report_2_total: (state) => {
      return state.report_2_data.reduce((acc, currValue) => acc + currValue.length_km, 0,)
    },

    report_6_total: (state) => {
      return state.report_6_data.reduce((acc, currValue) => acc + currValue.length_km, 0,)
    },

    report_7_total: (state) => {
      return state.report_7_data.reduce((acc, currValue) => acc + currValue.distance, 0,)
    },

    roadsInReport7: (state) => {
      const uniqueIds = new Set();
      const res = state.report_7_data.reduce((acc, item) => {
        if (!uniqueIds.has(item.road_id)) {
          uniqueIds.add(item.road_id);
          acc.push({ id: item.road_id, description: item.road_description });
        }
        return acc;
      }, []).sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))

      res.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      return res
    },

    report_8_total: (state) => {
      return state.report_8_data.reduce((acc, currValue) => acc + currValue.length_km, 0,)
    },

    report10_notNull: (state) => {
      const filteredRows = state.report_10_data.filter(row => {
        const nonEmptyFields = [row.aadt_a1_spc, row.aadt_b1_mb, row.aadt_b2_l2tr, row.aadt_b3_tractors,
        row.aadt_c1_m2tr, row.aadt_c2_h3tr, row.aadt_d1_buses, row.aadt_d2_truck_tr, row.aadt_d3_ar4tr_tr]
          .filter(field => field !== null && field !== undefined && field !== 0);
        return nonEmptyFields.length >= 9;
      });

      return filteredRows
    },

    report10_filtered: (state, getters) => (region_id, road_id, year) => {
      return getters.report10_notNull.filter(el =>
        (!region_id || el.region_id === region_id) &&  // Проверка на регион (если передан)
        (!road_id || el.road_id === road_id) &&  // Проверка на дорогу (если передана)
        (!year || el.survey_year === year)           // Проверка на год (если передан)
      )
    },

    roadsInReport10: (state, getters) => (region_id) => {
      const uniqueIds = new Set();
      const res = getters.report10_filtered(region_id).reduce((acc, item) => {
        if (!uniqueIds.has(item.road_id)) {
          uniqueIds.add(item.road_id);
          acc.push({ id: item.road_id, description: item.road_description });
        }
        return acc;
      }, [])
        .sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))

      res.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      return res
    },

    yearsInReport10: (state, getters) => {
      // Get all unique years in the list, order and return as an array of objects for YearsDropdown
      const res =  [...new Set(getters.report10_notNull.reduce((acc, item) => {
        if (item.survey_year !== null) acc.push(item.survey_year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => ({ id: year, description: year }));

      res.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      return res
    },

    report_11_total: (state) => {
      const zoneTotals = {
        zone_1: 0,
        zone_2: 0,
        zone_3: 0,
        zone_4: 0,
        zone_5: 0,
        zone_6: 0,
        total: 0
      };

      return state.report_11_data.reduce((totals, row) => {
        Object.keys(totals).forEach(zone => { totals[zone] += row[zone] || 0 });
        return totals;
      }, zoneTotals);
    },

    report12_filtered: (state) => (region_id, road_class) => {
      return state.report_12_data.filter(el =>
        (!region_id || el.region_id === region_id) &&
        (!road_class || el.road_class === road_class)
      )
    },

    report_12_total: (state, getters) => (region_id, road_class) => {
      const zoneTotals = {
        zone_1: 0,
        zone_2: 0,
        zone_3: 0,
        zone_4: 0,
        zone_5: 0,
        zone_6: 0,
        total: 0
      };

      return getters.report12_filtered(region_id, road_class).reduce((totals, row) => {
        Object.keys(totals).forEach(zone => { totals[zone] += row[zone] || 0 });
        return totals;
      }, zoneTotals);
    },
  }
}