<!--
File: RoadEditForm.vue
Description: form for adding/editing a single road.
-->
<template>
  <modal>
    <template slot='header'>
      <h4 class='modal-title'>{{ screenTitle }}</h4>
      <md-button class='md-simple md-just-icon md-round modal-default-button' @click='onModalClose'>
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot='body'>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-50">
          <RoadClassesDropdown :class="getClass('road_class')" v-model="road_class" data-vv-name="road_class"
            v-validate="modelValidations.road_class" :is_required="true" required />
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('road_number')">
            <label for="road_number">{{ $t('road_network.road_number') }}</label>
            <md-input id="road_number" v-model="road_number" type="number" data-vv-name="road_number" required
              v-validate="modelValidations.road_number" />
          </md-field>
        </div>
      </div>

      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('road_key')">
            <label for="road_key">{{ $t('road_network.road_key') }}</label>
            <md-input id="road_key" v-model="road_key" type="text" data-vv-name="road_key" required
              v-validate="modelValidations.road_key" />
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('road_length')">
            <label for="road_length">{{ $t('road_network.length') }}</label>
            <md-input id="road_length" v-model="road_length" type="number" data-vv-name="road_length" required
              v-validate="modelValidations.road_length" />
          </md-field>
        </div>
      </div>
      <!--
  uncomment this if we user MultilanguageEditForm and delete the <div> section below
          <multilanguage-edit-form :theTable="'road'" :theId=this.road_id :oper="this.oper" v-model=theValues
          data-vv-name="theValues"></multilanguage-edit-form>
-->
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('road_description_ru')">
          <label for="road_description_ru">{{ $t('stdCols.name_ru') }}</label>
          <md-input id="road_description_ru" v-model="road_description_ru" type="text" data-vv-name="road_description_ru"
            required v-validate="modelValidations.road_description_ru" />
        </md-field>
        <md-field :class="getClass('road_description_en')">
          <label for="road_description_en">{{ $t('stdCols.name_en') }}</label>
          <md-input id="road_description_en" v-model="road_description_en" type="text" data-vv-name="road_description_en"
            required v-validate="modelValidations.road_description_en" />
        </md-field>
      </div>

      <span v-if="saveBtnDisabled" class="md-error">
        {{ $t('messages.errors_count', { count: errors.count() }) }}
      </span>
    </template>

    <template slot='footer'>
      <md-button v-if="isBtnAllowed('SaveButton')" class="md-success" native-type="submit"
        @click.native.prevent="validate" :disabled="saveBtnDisabled">
        {{ $t('buttons.save') }}
      </md-button>
      <md-button class="md-accent" @click.stop.prevent="onModalClose">
        {{ $t('buttons.cancel') }}
      </md-button>
    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal } from '@/components'
  import { onModalClose } from '@/mixins/onCloseMixin'
  import messages from '@/mixins/messagesMixin'
  import permissions from "@/mixins/permissionsMixin"
  import RoadClassesDropdown from '@/pages/Components/RoadClassesDropdown.vue'

  export default {
    name: 'region-edit-form',
    mixins: [messages, permissions],

    data() {
      return {
        formName: 'RoadEditForm',
        screenTitle: '',

        road_class: '',
        road_number: null,
        road_key: null,
        road_description_en: null,
        road_description_ru: null,
        //      road_description_kg: null,
        road_length: null,

        modelValidations: {
          road_class: { required: true, min: 1 },
          road_number: { required: true, numeric: true, min_value: 1 },
          road_key: { required: true, min: 3 },
          road_description_en: { required: true, min: 3 },
          road_description_ru: { required: true, min: 3 },
          // road_description_kg: { required: true, min: 3 }
          road_length: { required: true, numeric: true, min_value: 1 },
        }
      }
    },

    props: {
      roadId: null
    },

    components: {
      Modal,
      RoadClassesDropdown
    },

    async mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onModalClose()
        return
      };

      if (this.roadId) {
        this.screenTitle = this.$t('route.road_upd')
        // Load data on existing region
        this.loadRoad(this.roadId).then((res) => {
          const theRoad = Array.isArray(res) ? res[0] : res
          this.road_key = theRoad.road_key
          this.road_class = theRoad.road_class
          this.road_number = theRoad.road_number
          this.road_length = theRoad.length_m
          // Delete these if we use MultilanguageEditForm 
          this.road_description_en = theRoad.road_description_en
          this.road_description_ru = theRoad.road_description
        })
      } else {
        this.screenTitle = this.$t('route.road_add')
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadRoad: 'LOAD_ROAD_BY_ID',
        addRoad: 'ADD_ROAD',
        editRoad: 'UPD_ROAD',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      onModalClose,   // Подключаем метод onModalClose в методы компонента

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theRoad = {
          fk_rn: 1,
          road_class: this.road_class,
          road_number: Number(this.road_number),
          length_m: Number(this.road_length),
          road_key: this.road_key,
          road_description_en: this.road_description_en,
          road_description: this.road_description_ru,
          //road_description_kg: this.road_description_kg
        }

        let errDesc = '';
        let newroadId;
        const action = !this.roadId ? this.addRoad : this.editRoad;
        const payload = !this.roadId ? this.theRoad : { id: this.roadId, theRoad };
        try {
          const res = await action(payload)
          newroadId = res?.road_id;
        } catch (err) {
          errDesc = err.message
        }

        this.$nextTick(() => this.$validator.reset())
        this.onModalClose()
        await this.savedMessage(errDesc, this.$t('road_network.road'), this.road_description_ru, this.road_description_en)
        this.highlightRow(newroadId)
      }
    },
  }
</script>
<style lang='scss' scoped>
.md-button+.md-button {
  margin-left: 10px;
}
</style>