<!--
File: Deu.vue
Description: show list of DEU's entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown v-model="selectedRegion" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery" />
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem()">
            {{ $t('buttons.add') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="100" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item.deu_id === highlightedRow }"
          :id="item.deu_id === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell v-if="!selectedRegion" :md-label="$t('road_network.region')" md-sort-by="region">
            {{ item.region }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.dep')" md-sort-by="description">
            {{ item.description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.address')" md-sort-by="address1">
            {{ item.address1 }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.city')" md-sort-by="city">
            {{ item.city }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="viewItem(item.deu_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="isBtnAllowed('DeleteButton')" class="md-just-icon md-danger md-simple"
                :title="$t('buttons.delete')" @click.stop.prevent="deleteItem(item.deu_id, item.description)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
    <DeuEditForm v-if="showDetailsDlg" :deuId="deuId" @close="showDetailsDlg = false" />
  </md-card>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import { Pagination } from '@/components'
  import { RegionsDropdown } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import listView from '@/mixins/listViewMixin'
  import Fuse from 'fuse.js'
  import DeuEditForm from './DeuEditForm.vue'

  export default {
    name: 'deus-list',
    mixins: [permissions, listView],

    data() {
      return {
        formName: 'Deu',
        selectedRegion: null,
        showSpinner: false,
        showDetailsDlg: false,
        deuId: null,

        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 30, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['region', 'description', 'city', 'address1'],
        currentSort: 'region',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },

    components: {
      Pagination,
      RegionsDropdown,
      DeuEditForm
    },

    mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onClose()
        return
      };

      this.showSpinner = true
      this.loadHistory().then((history) => {
        if (history.form === 'deus' && history.use) {
          this.selectedRegion = history.data.selectedRegion
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.highlightedRow = history.row
          this.$nextTick(() => {
            this.searchQuery = history.data.searchQuery
            this.pagination.currentPage = history.data.currentPage
          })
          this.clearHistory()
        }
        this.reloadData()
      })
    },

    methods: {
      ...mapActions({
        loadList: 'LOAD_DEU_LIST',
        delete: 'DEL_DEU',
      }),

      async reloadData() {
        this.showSpinner = true
        await this.loadList({ region_id: this.selectedRegion })
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 })
        this.showSpinner = false
      },

      save_history() {
        const hist = {
          form: 'deus',
          data: {
            selectedRegion: this.selectedRegion,
            searchQuery: this.searchQuery,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage,
          }
        }
        this.saveHistory(hist)
      },

      viewItem(deuId) {
        this.deuId = deuId
        this.showDetailsDlg = true
      },
    },

    computed: {
      ...mapState({
        deuList: (state) => state.RoadNetwork.deuList,
        highlightedRow: (state) => state.History.row
      }),

      tableData() {
        const res = this.deuList.map(item => ({
          ...item,
          description: `${this.$t('road_network.dep')}-${item.description}`
        }))
        return this.customSort(res, 'description')
      },
    },

    watch: {
      async highlightedRow(newVal) {
        if (newVal) {
          await this.reloadData();
          // calculate - which page edited item belongs to
          const ind = this.searchedData.findIndex(item => item.deu_id == newVal)
          this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
          this.scrollToHighlightedRow();
          this.clearHistory();
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
  outline: 0;
  width: 150px;
  border-right: 0;
  border-bottom: 0;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>