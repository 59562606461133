<!--
File: BaseDropdown.vue
Description: base component for showing the dropdown combo to be inherited by other dropdowns.
-->
<template>
  <md-field>
    <label :for="dropdownId">{{ label }}</label>
    <md-select v-model='selectedValue' :id="dropdownId" :disabled="disabled" :required="isRequired"
      @md-selected='onChange' :multiple="multiple">
      <md-option v-for='(item, index) in items' :key='index' :value='item[valueField]'>
        {{ item[displayField] }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'base-dropdown',
    props: {
      label: { default: null, type: String },
      value: { default: null, type: [String, Number, Array] },
      displayField: { default: "description", type: String },
      valueField: { default: "id", type: String },
      isRequired: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean },
      items: { default: () => [], type: Array },
      dropdownId: { default: 'dropdown', type: String },
      multiple: { default: false, type: Boolean },
    },

    data() {
      return {
        selectedValue: this.value || null,
        listLoaded: false
      }
    },

    mounted() {
      this.loadItems().then(() => {
        this.listLoaded = true;
        if (this.value && !this.selectedValue) this.selectedValue = this.value;
      })
    },

    methods: {
      loadItems() {
        return Promise.resolve();
      },

      onChange() {
        const descr = this.items.find(el => el[this.valueField] == this.selectedValue)?.[this.displayField] || ''
        this.$emit('input', this.selectedValue, descr);
      },
    },

    watch: {
      value(newValue) {
        if (newValue !== this.selectedValue) {
          this.selectedValue = newValue;
        }
      },
    }
  }
</script>
