<!--
File: ImportForm.vue
Description: form for bulk import/upload of road data.
-->
<template>
  <div class="md-layout">
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <BaseDropdown :label="$t('label.select_import_type')" v-model="selectedImportType" :items="importTypesList"
            :valueField="'import_type_id'" @input='onImportTypeChange' />
        </div>
        <div class="md-layout-item md-small-size-10.0 md-size-50">
          <md-field>
            <label for="fileName">{{ $t('traffic.select_file') }}</label>
            <md-file id="fileName" v-model="fileName.name" @md-change="onFileChange($event)" />
          </md-field>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-success md-raised" @click="templateDownload" :disabled="!selectedImportType">
            {{ $t('buttons.download_template') }}
          </md-button>
          <md-button v-if="isBtnAllowed('ImportButton')" class="md-success md-raised" native-type="submit"
            @click.native.prevent="validate" :disabled="uploadDisabled">
            <md-icon>upload</md-icon>
            {{ $t('buttons.import') }}
          </md-button>
          <md-button class="md-accent" @click.stop.prevent="onClose">
            {{ $t('buttons.cancel') }}
          </md-button>
        </div>
      </md-card-actions>
    </md-card>

    <md-card v-if="showPreview == true">
      <md-card-content>
        <div>
          <span><strong>{{ $t('upload.status') }}: </strong> {{ status }} </span><br>
        </div>
        <md-table v-model="importStatus" md-fixed-header class="paginated-table table-striped table-hover">
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('upload.line_number')">
              {{ item.line_number }}
            </md-table-cell>
            <md-table-cell :md-label="$t('upload.type_description')">
              {{ item.type_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('upload.is_error')">
              {{ item.is_error == true ? $t('buttons.yes') : $t('buttons.no') }}
            </md-table-cell>
            <md-table-cell :md-label="$t('upload.message')" md-sort-by="message" width="10%">
              {{ item.message }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import { onClose } from '@/mixins/onCloseMixin'
  import permissions from "@/mixins/permissionsMixin"
  import messages from '@/mixins/messagesMixin'
  import BaseDropdown from '@/pages/Components/BaseDropdown.vue'

  export default {
    name: 'import-sections',
    mixins: [permissions, messages],

    data() {
      return {
        formName: 'ImportForm',

        importFile: null,
        fileName: { name: '' },
        maxFileSize: null,
        selectedImportType: null,
        importTypeKey: null,

        showPreview: false,
        status: null,
        batch_id: null,

        uploadInProgress: false
      }
    },

    components: {
      BaseDropdown
    },

    mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onClose()
        return
      };

      this.loadSettings('MaxFileSize').then((res) => {
        this.maxFileSize = res * 1024   // in KB
      })

      this.loadImportTypes().then(() => {
      })
    },

    methods: {
      ...mapActions({
        loadSettings: 'LOAD_SETTING_BY_NAME',
        loadImportTypes: 'GET_IMPORT_TYPES_DROPDOWN',
        importLog: 'IMPORT_LOG_ALL',
      }),
      ...mapActions(['GET_ROAD_IMPORT_TEMPLATE', 'GET_SECTION_IMPORT_TEMPLATE', 'RUN_ROAD_IMPORT', 'RUN_SECTION_IMPORT']),

      onClose,

      onFileChange(evt) {
        this.importFile = evt[0]
        this.showPreview = false
        this.status = null
      },

      templateDownload() {
        const importTemplate = this[`GET_${this.importTypeKey.toUpperCase()}_IMPORT_TEMPLATE`]
        importTemplate().then((res) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob([res]))
          link.download = `${this.importTypeKey}_import_template.xlsx` //"road_import_template.xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
      },

      async validate() {
        const fileInfo = `${this.$t('stdCols.name')}: ${this.importFile.name},
                  ${this.$t('label.filesize', { size: this.importFile.size })}`

        // Checks and validations
        let errors = []
        if (this.importFile.type.includes("image")) {
          errors.push(`${errors.length + 1}. ${this.$t('messages.wrong_file_type')}`)
        }
        if (this.importFile.size > this.maxFileSize) {
          errors.push(`${errors.length + 1}. ${this.$t('messages.big_file')}`)
        }

        if (errors.length > 0) {
          errors.push('', fileInfo)
          this.errorMessage(errors.join('<br>'))
          return
        }

        // We passed all validations - let's try to import
        this.uploadInProgress = true
        let formData = new FormData();
        formData.append('file', this.importFile)
        try {
          const runImport = this[`RUN_${this.importTypeKey.toUpperCase()}_IMPORT`]
          const res = await runImport(formData)
          this.status = res.msg + ", " + res.result
          this.batch_id = res.batch_id
          this.successMessage(this.$t('route.import'), this.$t(`messages.import_file_queued`))
          this.importFile = null
          this.fileName.name = ''
        } catch (err) {
          this.status = err.msg
          this.batch_id = err.batch_id
          this.errorMessage(this.$t(`messages.import_error`))
        }
        this.get_log(this.batch_id)
        this.uploadInProgress = false
        this.showPreview = true
      },

      get_log(batch_id) {
        if (!batch_id) return
        this.importLog(batch_id).then(() => {
          console.log(this.importStatus)
        })
      },

      onImportTypeChange(import_type_id, key) {
        this.importTypeKey = key
        this.importFile = null
        this.fileName.name = ''
      }
    },

    computed: {
      ...mapState({
        importStatus: (state) => state.ImportTools.import_status,
        importTypesList: (state) => state.Dropdowns.import_types
      }),

      uploadDisabled() {
        return this.importFile === null || this.uploadInProgress === true
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}
</style>