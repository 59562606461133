<!--
File: Roads.vue
Description: show list of roads entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RoadClassesDropdown v-model="selectedRoadClass" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="text" class="mb-3" clearable :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem()">
            {{ $t('buttons.add') }}
          </md-button>
          <md-button v-if="closePath" class="md-accent" @click.stop.prevent="onClose">
            {{ $t('buttons.close') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="100" md-mode="indeterminate" />
      <md-table :value="queriedData" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item.road_id === highlightedRow }"
          :id="item.road_id === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell :md-label="$t('road_network.road_class')" md-sort-by="road_class">
            {{ item.road_class }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.road_number')" md-sort-by="road_number">
            {{ item.road_number }}
          </md-table-cell>
          <md-table-cell style="min-width: 50%;" :md-label="$t('stdCols.name')" md-sort-by="road_description">
            {{ item.road_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length')" md-sort-by="length_m">
            {{ item.length_m | numFormat(0) }}
          </md-table-cell>
          <md-table-cell style="text-align: center;" :md-label="$t('road_network.section_count')"
            md-sort-by="section_count">
            <a v-if="item.section_count > 0" href='#' @click.stop.prevent='showSections(item.road_id)'>
              {{ item.section_count }}
            </a>
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div class="md-layout-item btn-row md-small-size-100">
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="viewItem(item.road_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="isBtnAllowed('DeleteButton')" class="md-just-icon md-danger md-simple"
                :title="$t('buttons.delete')" @click.stop.prevent="deleteItem(item.road_id, item.road_description)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for=" item in pagination.perPageOptions " :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
    <EditForm v-if="showDetailsDlg" :roadId="roadId" @close="showDetailsDlg = false" />
  </md-card>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { Pagination } from '@/components'
  import permissions from "@/mixins/permissionsMixin"
  import listView from '@/mixins/listViewMixin'
  import RoadClassesDropdown from '@/pages/Components/RoadClassesDropdown.vue'
  import Fuse from 'fuse.js'
  import EditForm from './RoadEditForm.vue'

  export default {
    name: 'roads-list',
    mixins: [permissions, listView],

    data() {
      return {
        formName: 'Roads',
        selectedRoadClass: null,  //'',
        showSpinner: false,
        showDetailsDlg: false,
        roadId: null,

        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 30, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['road_description', 'road_class', 'road_number', 'length_m'],
        currentSort: 'road_number',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },
    components: {
      Pagination,
      RoadClassesDropdown,
      EditForm
    },

    mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onClose()
        return
      };

      this.loadHistory().then((history) => {
        if (history.form === 'roads' && history.use) {
          this.selectedRoadClass = history.data.selectedRoadClass
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.searchQuery = history.data.searchQuery
          //this.highlightedRow = history.row
          this.$nextTick(() => {
            this.pagination.currentPage = history.data.currentPage
          })
          this.clearHistory()
        }
        this.reloadData()
      })
    },

    methods: {
      ...mapActions({
        loadList: 'LOAD_ROADS_LIST',
        delete: 'DEL_ROAD',
      }),

      async reloadData() {
        this.showSpinner = true
        const savedSearchQuery = this.searchQuery
        this.searchQuery = ''
        this.loadList(this.selectedRoadClass).then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.1 })
          if (savedSearchQuery) this.searchQuery = savedSearchQuery
          this.showSpinner = false
        })
      },

      save_history() {
        const hist = {
          form: 'roads',
          data: {
            selectedRoadClass: this.selectedRoadClass,
            searchQuery: this.searchQuery,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage
          }
        }
        this.saveHistory(hist)
      },

      /*viewItem(id) {
        this.save_history()
        const path = !id ? '/inventory_data/roads/add' : `/inventory_data/roads/upd/${id}`
        this.$router.push(path)
      },*/
      viewItem(id) {
        this.roadId = id
        this.showDetailsDlg = true
      },

      showSections(roadId) {
        const hist = {
          form: 'sections',
          data: {
            selectedRoad: roadId,
            perPage: 20,
            currentPage: 1
          },
          use: true,
          closePath: window.location.pathname  // current URL path
        }
        this.saveHistory(hist)
        this.$router.push('/inventory_data/sections')
      },
    },

    computed: {
      ...mapState({
        tableData: (state) => state.RoadNetwork.roadsList,
        highlightedRow: (state) => state.History.row
      }),

      ...mapGetters(['closePath']),
    },

    watch: {
      async highlightedRow(newVal) {
        if (newVal) {
          await this.reloadData();
          // calculate - which page edited item belongs to
          const ind = this.searchedData.findIndex(item => item.road_id == newVal)
          this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
          this.scrollToHighlightedRow();
          this.clearHistory();
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>