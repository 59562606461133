// store/apicall_helper.js
// single function to call different API's
export async function handleApiCall({
  request, // Promise
  caller,  // Name of the calling method (for debugging)
  commit,  // Optional: commit function
  mutation, // Optional: mutation name to be called by commit
  expectedStatus = 200, // Expected status
}) {
  try {
    const { status, data } = await request;
    if (status === expectedStatus) {
      // call mutation if provided
      if (commit && mutation) {
        commit(mutation, data);
        return 'success';
      }
      return data; // Return as data if no commit provided
    } else {
      throw new Error(`Error ${caller}: ${status}`);
    }
  } catch ({ response }) {
    // Error handling
    throw new Error(response?.data?.msg || response?.data?.message || `Failed: ${caller}`);
  }
}

export async function apiCall(request, caller, expectedStatus = 200) {
  // Simplified version for calling API's
  return handleApiCall({
    request,
    caller,
    expectedStatus
  });
}
