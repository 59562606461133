<!--
File: Permissions.vue
Description: show list of permissions entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <BaseDropdown :label="$t('select role')" v-model="selectedRole" :items="rolesList" :displayField="'description'"
            :valueField="'description'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <BaseDropdown :label="$t('select type')" v-model="selectedType" :items="getPermissionTypes"
            :displayField="'name'" :valueField="'name'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addPermission"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
      <md-table :value="searchedData" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
              <md-table-cell :md-label="$t('label.component_type')" md-sort-by="component_type">
            {{ item.component_type }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="form_name">
            {{ item.form_name }}
          </md-table-cell>
          <md-table-cell :md-label="$t('form_name')" md-sort-by="form_name">
            {{ item.form_name }}
          </md-table-cell>
          <md-table-cell :md-label="$t('api_path')" md-sort-by="api_path">
            {{ item.api_path }}
          </md-table-cell>

          <!--template v-if="selectedRole"-->
            <md-table-cell :md-label="selectedRole" md-sort-by="selectedRole">
              {{ item[selectedRole] }}
            </md-table-cell>
          <!--/template>
          <template v-else>
            <md-table-cell v-for="role in rolesList" :key='role.name' :md-label="role.name"
              md-sort-by='role.name'>
              {{ item[role.name] }}
            </md-table-cell>
          </template-->

          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="handleEdit(item.id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { Pagination } from '@/components'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messagesMixin from '@/mixins/messagesMixin'
  import Fuse from 'fuse.js'
  import BaseDropdown from '@/pages/Components/BaseDropdown.vue'

  export default {
    name: 'permissions-list',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        selectedRole: '',
        selectedType: null,
        showSpinner: false,

        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['form_name', 'form_path', 'api_path'],
        currentSort: 'form_name',
        currentSortOrder: 'asc',
        fuseSearch: null
      }
    },

    components: {
      Pagination,
      BaseDropdown
    },

    async mounted() {
      this.toggleSpinner(true)
      await this.getRolesDropdown(false)
      if (this.rolesCount) this.selectedRole = this.rolesList[0].description

      if (!this.permissionsCount) {
        await this.loadUserPermissions()
      }

      this.loadHistory().then(history => {
        if (history.form === 'permissions' && history.use) {
          this.selectedRole = history.data.selectedRole
          //this.selectedType = history.data.selectedType
          this.searchQuery = history.data.searchQuery || this.searchQuery
          this.currentSort = history.data.currentSort || this.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.$nextTick(() => {
            this.pagination.currentPage = history.data.currentPage
          });
          this.clearHistory()
        }
      })
      this.toggleSpinner(false)
    },

    methods: {
      ...mapActions({
        getRolesDropdown: 'GET_ROLES_DROPDOWN',
        loadUserPermissions: 'LOAD_USER_PERMISSIONS_PIVOT',
        loadHistory: 'LOAD_HISTORY',
        clearHistory: 'CLEAR_HISTORY'
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },


      handleEdit(id) {
        //this.successMessage('Editing permission', 'Saved')
      },

      addPermission() {
        //this.errorMessage('Adding new permission')
      },

      handleDelete(item) {
        //this.deletedMessage(`${this.$t('label.component_type')}: ${item.component_type}, name:${item.name}`)
      }
    },

    computed: {
      ...mapState({
        // permissionsList: (state) => state.RBAC.userPermissionsList,
        rolesList: (state) => state.Dropdowns.roles,
      }),

      ...mapGetters(['amIAdmin', 'getPermissionTypes', 'getPermissionsByType']),

      rolesCount() {
        return this.rolesList.length
      },

     permissionsList() {
        const res = this.getPermissionsByType(this.selectedType)
        this.fuseSearch = new Fuse(res, { keys: this.propsToSearch, threshold: 0.1 });
        return res
      },

      permissionsCount() {
        return this.permissionsList.length
      },

      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },

      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },

      total() {
        return this.searchQuery ? this.searchedData.length : this.permissionsCount
      },

      searchedData() {
        if (!this.permissionsCount) {
          return []
        } else {
          return (!this.searchQuery) ? this.permissionsList : this.fuseSearch.search(this.searchQuery)
        }
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>