<template>
  <div class="main-zone">
    <md-progress-spinner v-if="showSpinner == true" :md-diameter="100" md-mode="indeterminate" />
    <div id="zoomlevel"></div>
    <div id="map2" class="map2"></div>
    <span>{{ $t('budget.selected') }} {{ $t(selection_info) }}
      <a v-if="selection_url" href="#" @click.stop.prevent="onSelectionClick()">{{ $t(selection_url.title) }}</a>
    </span>
    <div id="legend" v-show="showLegendButton" class="ol-legend ol-unselectable ol-control">
      <button @click="toggleLegend">...</button>
      <div v-if="showLegendBox" class="legend-box">
        <div class="legend-label" v-for="elem in vectorLegend" :key="elem.name">
          <span>
            <md-icon :style="{ color: elem.colour }">circle</md-icon>
            {{ $t(elem.name) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { arrayCompare, getRangeVal } from '@/store/helpers/arrayCompare_helper'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import olMap from '@/store/helpers/olMap_helper'
  import { getLegend } from '@/store/helpers/ranges_helper';

  export default {
    props: {
      tileLayer: { type: String, default: 'osm' },
      mapDefaultParams: { type: Object },
      filterOptions: { type: Object },
      trackedEvents: { type: Array }
    },

    components: {
    },

    data() {
      return {
        selectedLayer: 'roads',
        showSpinner: false,

        initialZoomLevel: 11,
        initialExtent: null,

        zoomToSelection: false, // to zoom to the selected road/section
        currZoomLevel: 11,
        currExtent: null,
        showLegendBox: true,
        ol: undefined,

        parameter: '',
        filter: '',
        filterValue: '',
        selection_url: {},
      }
    },

    beforeDestroy() {
      this.$eventHub.$off('viewGridClicked')
      this.$eventHub.$off('resetStatus')
      this.$eventHub.$off('vectorLayerChanged')
      this.$eventHub.$off('paramTypeChanged')
      this.$eventHub.$off('zoomToSelection')
      this.ol.destroy()
    },

    async created() {
      const {
        map_centre,
        max_zoom,
        data_projection,
        view_projection,
        zoom
      } = this.mapDefaultParams

      this.ol = new olMap({
        center: map_centre,
        zoom: zoom,
        extent: this.currExtent,
        viewProjection: view_projection,
        dataProjection: data_projection,
        viewMaxZoomDefault: max_zoom,
        vectorLayerList: ['bridges', 'tunnels', 'roads', 'coloringLayer',]   //'pavedRoadsLayer']
      })

      this.showSpinner = true
      const { basemap_tiles } = await this.loadBasemapTypes()
      const tileList = this.ol.createTileLayers(basemap_tiles)
      this.$emit('changeTileList', tileList)
      this.changeTileLayer()

      this.setVisible({ layer: 'coloringLayer' })

      const params = {
        zoom: Math.trunc(this.ol.getZoom()),
        start_x: 80347,
        start_y: 4443524,
        end_x: 1027105,
        end_y: 4767255
      }
      await this.loadRoadsCoords(params)
      this.updateLayerData({ layer: 'roads', source: this.roadsGeoJSON })

      await this.loadBridgesTunnels()

      this.$eventHub.$on('viewGridClicked', this.onViewGridClicked)
      this.$eventHub.$on('vectorLayerChanged', this.onResetStatus)
      this.$eventHub.$on('paramTypeChanged', this.onParamTypeChanged)
      this.$eventHub.$on('resetStatus', this.onResetStatus)
      this.$eventHub.$on('zoomToSelection', this.onZoomToSelection)
      this.showSpinner = false
    },

    mounted() {
      const fnEvents = [
        { type: 'singleclick', fn: this.onMapClick },
        //{ type: 'pointermove', fn: this.onPointerMove }
      ]
      this.$nextTick(function () {
        this.ol.init({ overlayElem: 'popup', mapElem: 'map2' })

        this.refreshSizeByExtent()

        this.initialExtent = this.ol.getMapExtent()
        this.initialZoomLevel = this.ol.getZoom()

        this.currExtent = this.initialExtent
        this.currZoomLevel = this.initialZoomLevel

        this.trackedEvents.forEach((event) => {
          const evtIndex = fnEvents.findIndex((item) => item.type === event)
          if (~evtIndex) {
            this.ol.setEventHandler(
              fnEvents[evtIndex].type,
              fnEvents[evtIndex].fn
            )
          }
        })
      })
    },

    methods: {
      ...mapActions({
        loadCoords: 'LOAD_COORDS',
        loadRoadsCoords: 'LOAD_ROADS_COORDS',
        loadBasemapTypes: 'LOAD_BASEMAP_TYPES',
      }),

      async loadBridgesTunnels() {
        this.showSpinner = true
        // loading coords for bridges and tunnels once
        let geoJSON = await this.loadCoords('bridges')
        this.updateLayerData({ layer: 'bridges', source: geoJSON })
        this.setVisible({ layer: 'bridges' })

        geoJSON = await this.loadCoords('tunnels')
        this.updateLayerData({ layer: 'tunnels', source: geoJSON })
        this.setVisible({ layer: 'tunnels' })
        this.showSpinner = false
      },

      async onViewGridClicked({ layer, filter, value, desc }) {
        console.log('viewGridClicked', layer, filter, value, desc)

        this.filter = filter
        this.filterValue = value
        this.selectedLayer = layer

        const status = ['road_class', 'fk_region', 'fk_road', 'section_id', 'fk_deu'].includes(filter)
        this.setStatusLine(status ? desc : null)
      },

      async onParamTypeChanged({ layer, value }) {
        console.log('onParamTypeChanged', layer, value)
        this.selectedLayer = layer
        this.parameter = value
      },

      async onResetStatus(layer) {
        this.showSpinner = true
        console.log('onResetStatus switched to layer', layer)
        //console.log('onResetStatus: initialExtent. currExtent', this.initialExtent, this.currExtent)

        this.ol.map.getView().setCenter(this.mapDefaultParams.map_centre)
        this.ol.map.getView().setZoom(this.initialZoomLevel)
        this.currZoomLevel = this.initialZoomLevel
        this.currExtent = this.initialExtent

        //this.setStatusLine()
        this.selectedLayer = layer || 'roads'
        //this.parameter = 'road_class'
        this.showSpinner = false
      },

      onZoomToSelection(value) {
        this.zoomToSelection = value
      },

      setStatusLine(desc = null, selection_url = null) {
        this.selection_url = selection_url || {}
        this.$store.commit('SET_SELECTION_INFO', desc || 'map.nothing')
      },

      async setVisible(payload) {
        await this.ol.setVisible(payload)
      },

      async updateLayerData(payload) {
        this.showSpinner = true
        await this.ol.updateVectorSource(payload)
        this.showSpinner = false
      },

      onSelectionClick() {
        this.$router.push({ path: `${this.selection_url.href}` })
      },

      toggleLegend() {
        this.showLegendBox = !this.showLegendBox
      },

      refreshSizeByExtent() {
        this.ol.fitMapByExtent(this.currExtent)
      },

      changeTileLayer() {
        this.ol.changeTileLayer(this.tileLayer)
      },

      async onMapClick(evt) {
        const selectedPointInfo = this.ol.getFeatureInfoByCoord(evt)

        let item_id = null
        let clicked_coords = null

        let desc = null
        let content = null
        if (selectedPointInfo) {
          console.log('onMapClick', selectedPointInfo.feature_data, this.selectedLayer)
          item_id = selectedPointInfo.feature_data[2]
          content = { title: this.$t(selectedPointInfo.feature_data[1].link), href: selectedPointInfo.feature_data[1].href }
          desc = (this.selectedLayer === 'coloringLayer') ? `${this.$t('map.roads')} - ` : `${this.$t('map.' + this.selectedLayer)} - `
          if (this.selectedLayer === 'roads') {
            clicked_coords = selectedPointInfo.feature_data[3]
            this.filter = 'section_id'
            this.filterValue = item_id
          } else desc += selectedPointInfo.feature_data[0]
        }
        this.setStatusLine(desc, content)
        this.$eventHub.$emit('mapItemSelected', { layer: this.selectedLayer, item_id, clicked_coords })
      },

      async onClearLayerData(layer) {
        this.ol.clearVectorSource(layer)
      },

      async onLoadLayerData(layer) {
        console.log('this.filter, this.parameter', this.filter, this.parameter)
        if (!this.filter && this.parameter) return

        this.showSpinner = true
        const mapViewExtent = this.ol.getMapExtent()
        const mapViewZoom = Math.trunc(this.ol.getZoom())

        let payload = {
          zoom: mapViewZoom,
          /* // Finish - check when switching to another road/section should be another extent
            start_x: Math.round(mapViewExtent[0]),      //80347,
            start_y: Math.round(mapViewExtent[1]),      //4443524,
            end_x: Math.round(mapViewExtent[2]),        //1027105,
            end_y: Math.round(mapViewExtent[3]),         //4767255
        */
          //if no filter is give the browser will hang - resolve later when API fill everything
          data_100_column_name: (this.filter) ? this.parameter : null
        }
        if (this.selectedLayer === 'coloringLayer') {
          const filterMapping = {
            fk_region: 'region_id',
            fk_road: 'road_id',
            fk_deu: 'deu_id',
            section_id: 'section_id', // или 'section_description', если нужно
          };

          const key = filterMapping[this.filter];
          if (key) {
            payload[key] = this.filterValue;
          }
        }
        if (payload) {
          try {
            await this.loadRoadsCoords(payload);
            console.log('paramsGeoJSON', this.paramsGeoJSON);

            await this.updateLayerData({ layer, source: this.paramsGeoJSON });

            this.currExtent = this.zoomToSelection ? this.mapExtent : this.initialExtent
          } catch (err) {
            console.error(`Error loading roads coords: ${err}`);
          }
        }
        this.showSpinner = false
      }
    },

    computed: {
      ...mapState({
        roadsGeoJSON: (state) => state.MapComponent.roadsGeoJSON,
        paramsGeoJSON: (state) => state.MapComponent.paramsGeoJSON,
        selection_info: (state) => state.MapComponent.selection_info,
        mapExtent: (state) => state.MapComponent.mapExtent,
      }),

      showLegendButton() {
        return Boolean(this.vectorLegend)
      },

      vectorLegend() {
        return getLegend((!this.parameter || this.parameter === 'road_class') ? this.selectedLayer : this.parameter)
      },

      getLayerFeatures(layer) {
        return this.ol.getFeatures(layer)
      },
    },

    watch: {
      tileLayer(/*newVal*/) {
        this.changeTileLayer()
      },

      selectedLayer(newVal, oldVal) {
        this.setVisible({ layer: oldVal })
        this.setVisible({ layer: newVal, show: true })
        if (newVal === 'roads') this.parameter = 'road_class'

        this.setStatusLine()
      },

      filterValue(newVal, oldVal) {
        console.log('filterValue', newVal, oldVal)
        this.onLoadLayerData('coloringLayer')
      },

      parameter(newVal, oldVal) {
        console.log('parameter', newVal, oldVal)
        if (this.selectedLayer !== 'roads') this.onLoadLayerData('coloringLayer')
      },


      zoomToSelection(newValue, oldValue) {
        //if (newValue !== oldValue && newValue)
        this.currExtent = newValue ? this.mapExtent : this.initialExtent
      },
      
      currExtent(newValue) {
        this.refreshSizeByExtent()
      }
    }
  }
</script>

<style lang="scss">
#map2 {
  width: 100%;
  height: 100%;

  canvas {
    max-width: unset;
  }

  &:hover {
    .ol-mouse-position {
      background: white;
    }
  }
}

.main-zone {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ol-zoom {
  top: 2.5em;
  left: 0.5em;
}

.ol-legend {
  right: 0.5em;
  top: 2.8em;

  .legend-box {
    position: absolute;
    padding: 5px;
    margin-top: 7px;
    border-radius: 10px;
    right: 0.2em;
    background-color: rgba(255, 255, 255, 0.7);

    .legend-label {
      font-size: 12px;
      display: flex;
      align-items: center;

      i {
        margin-top: 3px;
      }

      span {
        white-space: nowrap;
        padding-left: 5px;
        font-weight: bold;
      }
    }
  }
}

.ol-mouse-position {
  border-radius: 5px;
  padding: 1px 3px;
  top: 8px;
  right: unset;
  left: 8px;
  position: absolute;
}

.ol-zoomslider {
  top: 6.3em;
}

#zoomlevel {
  display: none;
}

.popupbox-content {
  text-align: center;
}

.md-progress-spinner {
  margin: 24px;
  position: absolute;
  top: 25%;
  left: 55%;
  z-index: 10;
}
</style>
