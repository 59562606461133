<!--
File: Plans.vue
Description: Component of Preparatory.vue, shows list of plans
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RegionsDropdown v-model="selectedRegion" :is_required='false' @input="loadPlanList" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <YearsDropdown v-model="selectedYear" :items="yearsInPlanList" />
          </div>
          <!-- TODO: Check - somehow stopped working 
            div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <md-input type="search" clearable v-model="searchQuery" :placeholder="$t('label.search_records')" />
            </md-field>
          </div-->
          <div v-if="selectedYear && showPrintBtn" class="md-layout-item md-size-50 btn-row">
            <div class="md-layout-item md-size-20">
              <AmountsDropdown v-model="showAmounts" @input="onAmountChange" />
            </div>
            <md-button v-if="isBtnAllowed('PrintButton')" class="md-success" @click="print">
              {{ $t('buttons.print') }}
            </md-button>
            <md-button v-if="isBtnAllowed('ExportButton')" class="md-success" @click="exporttPlans">
              {{ $t('buttons.excel_export') }} 
            </md-button>
          </div>
          <!--div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="addPlan"> {{ $t('buttons.add') }} </md-button>
          </div-->
        </div>
      </md-card-header>
      <md-card-content>
        <md-progress-spinner v-show="showSpinner" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
        <md-table class="paginated-table table-striped table-hover" :value="queriedData" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('road_network.region')">
              {{ item.region_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('stdCols.name')">
              {{ item.name }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.year')" md-numeric>
              {{ item.year }}
            </md-table-cell>
            <md-table-cell :md-label="$t('stdCols.created_at')">
              {{ item.created_at | dateFormat }}
            </md-table-cell>

            <md-table-cell :md-label="$t('budget.works_count')" md-numeric>
              {{ item.assigned_treatments }}
            </md-table-cell>
            <md-table-cell :md-label="$t('label.approved')">
              {{ item.approved_on | dateFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('tables.actions')">
              <div class="cell-actions">
                <!--md-button v-if="!item.approved_on" class="md-just-icon md-success md-simple" @click="editPlan(item)"
                  :title="$t('buttons.edit')">
                  <md-icon>edit</md-icon>
                </md-button-->
                
                <md-button v-if="isBtnAllowed('ApproveRevokeButton') && (item.assigned_treatments > 0 || item.approved_by_user_id)"
                  :class="['md-raised', 'md-sm', item.approved_by_user_id ? 'md-primary' : 'md-success']"
                  @click="togglePlanApproval(item.id, item.name, !item.approved_by_user_id)">
                  {{ item.approved_by_user_id ? $t('buttons.revoke') : $t('buttons.approve') }}
                </md-button>
                <md-button v-if="isBtnAllowed('DeleteButton') && !item.approved_by_user_id"
                  class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                  @click.stop.prevent="handleDelete(item)">
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for="item in footerTable" :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="showEditForm" :md-click-outside-to-close="false">
      <md-dialog-title>{{ editFormTitle }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='showEditForm = false'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <PlanEditForm :plan="selectedPlan" @close="showEditForm = false" @saved="onPlanSave" />
    </md-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { RegionsDropdown, YearsDropdown, AmountsDropdown } from '@/pages/Components'
import { customSortMixin } from '@/mixins/customSortMixin'
import { numFormat } from "@/store/helpers/format_helpers"
import permissions from "@/mixins/permissionsMixin"
import messages from '@/mixins/messagesMixin'
import Fuse from 'fuse.js'
import PlanEditForm from './PlanEditForm.vue'
import printReport from '@/mixins/printMixin'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default {
  name: 'Plans',
  mixins: [permissions, customSortMixin, messages, printReport],

  data() {
    return {
      formName: 'Plans',

      selectedRegion: null,
      selectedYear: null,
      showEditForm: false,
      selectedPlan: null,
      editFormTitle: '',
      showSpinner: false,

      showAmounts: 1000,
      costLabel: "Cost",

      searchQuery: '',
      propsToSearch: ['region_description', 'name', 'year'],
      searchedData: [],
      currentSort: 'region_description',
      currentSortOrder: 'asc',
      fuseSearch: null,
    }
  },

  props: {
    step: { default: 'first', type: String },
  },

  components: {
    RegionsDropdown,
    AmountsDropdown,
    YearsDropdown,
    PlanEditForm
  },

  mounted() {
    // Check if we are eligible to view the form
    if (!this.checkIfScreenAllowed()) {
      this.onClose()
      return
    };

    this.onAmountChange(this.showAmounts, this.$t("label.short_thousands"))
  },

  methods: {
    toggleSpinner(state) {
      this.showSpinner = state
    },

    onAmountChange(value, desc) {
      this.costLabel = `${this.$t('condition.cost')} ${desc}`
    },

    loadPlanList() {
      this.$store.dispatch('LOAD_PLAN_LIST', { region_id: this.selectedRegion }).then(() => {
        if (this.tableData && this.tableData.length) {
          this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 });
        }
      })
    },

    editPlan(item) {
      this.editFormTitle = this.$t('route.plan_upd')
      this.selectedPlan = item
      this.showEditForm = true
    },

    addPlan() {
      this.editFormTitle = this.$t('route.plan_add')
      this.selectedPlan = null
      this.showEditForm = true
    },

    onPlanSave() {
      this.showEditForm = false
      this.loadPlanList()
    },

    togglePlanApproval(id, name, status) {
      this.toggleSpinner(true)
      this.$store.dispatch(status ? 'APPROVE_PLAN' : 'CANCEL_PLAN', id).then(() => {
        this.loadPlanList()
        this.approvedMessage(status, name)
        this.toggleSpinner(false)
      })
    },

    async handleDelete(item) {
      const res = await this.deleteConfirmation(item.name)
      if (!res.value) {
        return
      }
      this.$store.dispatch('DELETE_PLAN', item.id).then(
        () => {
          this.loadPlanList()
          this.deletedMessage(item.name)
        },
        (err) => {
          this.errorMessage(err.message)
        }
      )
    },

    async loadTreatmentsList() {
      const worklist = !this.savedWorksList ? null : this.savedWorksList.find(el => el.year == this.selectedYear)?.work_list_id
      if (!worklist) {
        return
      }

      this.toggleSpinner(true)
      const params = {
        work_list_id: worklist,
        region_id: this.selectedRegion,
        //     is_plan_assigned: 1,
        is_approved_plan_assigned: 1,
        //     is_budget_assigned: 0,
      }
      await this.$store.dispatch('LOAD_ALL_WORKS', params)
      this.toggleSpinner(false)
    },

    async print() {
      if (!this.selectedYear) return
      this.showSpinner = true

      await this.loadTreatmentsList()

      if (this.treatmentsGroupedByPlan.length == 0) {
        // noting to print
        this.showSpinner = false
        return
      }
      this.printReport('', this.getTableHeaders, this.getTableRows)
      this.showSpinner = false
    },

    fillWorkSheet(workbook, workbookName) {
      const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
      const totalCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' } } // yellow
      const worksheet = workbook.addWorksheet(workbookName)

      // Set the table headers
      worksheet.columns = this.getWorksheetColumns

      // Apply formatting to the table headers
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell({ includeEmpty: true }, (cell) => { cell.fill = headerCellFill })
      headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
      headerRow.alignment = { vertical: 'middle', horizontal: 'center' };

      // Number formatting for the columns
      worksheet.getColumn('units').numFmt = '#,##0.00'
      worksheet.getColumn('cost').numFmt = '#,##0.00'

      for (const [groupName, group] of Object.entries(this.treatmentsGroupedByPlan)) {
        let row = worksheet.addRow(['', groupName])
        row.font = { bold: true }

        group.forEach(item => {
          worksheet.addRow(item)
          console.log(item)
        })

        // Group total row
        row = worksheet.addRow(
          this.getWorksheetColumns.map(col => {
            if (col.group) {
              return this.$t('label.total');
            }
            if (col.totals) {
              return this.treatmentsGroupTotals[groupName][col.totals];
            }
            return '';
          })
        );
        row.font = { bold: true };
        row.eachCell({ includeEmpty: true }, (cell) => { cell.fill = totalCellFill });
      }
    },

    async exporttPlans() {
      if (!this.selectedYear) return
      this.showSpinner = true

      await this.loadTreatmentsList()
      if (this.treatmentsGroupedByPlan.length == 0) {
        // noting to print
        this.showSpinner = false
        return
      }

      const workbook = new ExcelJS.Workbook();
      this.fillWorkSheet(workbook, this.$t('label.plans'))

      // Генерация Excel файла и сохранение
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${this.$t('label.approved_plans')}-${this.selectedYear}.xlsx`);
    }
  },

  computed: {
    ...mapState({
      savedWorksList: (state) => state.TitleList.worksLists,
      treatmentsList: (state) => state.TitleList.treatmentsList,
      //planList: (state) => state.Plans.list
    }),
    ...mapGetters(['amIAdmin', 'planListByYear', 'yearsInPlanList', 'plansApproved']),
    queriedData() {
      return !this.searchQuery ? this.tableData : this.searchedData
      //return result.slice(this.from, this.to)
    },
    total() {
      return this.tableData.length
    },
    tableData() {
      return this.planListByYear(this.selectedYear)
    },
    footerTable() {
      return [
        this.$t('label.total'),
        `${this.total} ${this.$t('label.entries')}`
      ]
    },

    showPrintBtn() {
      return this.plansApproved(null, this.selectedYear, false).length > 0
    },

    treatmentsGroupedByPlan() {
      let index = 0
      let res = this.treatmentsList
        .sort((a, b) => {
          const planDescA = a.plan_description || ''
          const planDescB = b.plan_description || ''
          let sortRes = planDescA.localeCompare(planDescB, undefined, { sensitivity: 'base' })
          if (sortRes == 0) {
            const descA = a.section_description || ''
            const descB = b.section_description || ''
            sortRes = descA.localeCompare(descB, undefined, { sensitivity: 'base' });
          }
          if (sortRes == 0) { sortRes = a.start_m - b.start_m }
          return sortRes
        })

        .reduce((groups, row) => {
          const group = `${this.$t('label.from_region', { name: row.region_description })}, 
                                ${this.$t('label.plan_name', { name: row.plan_description })}`
          if (!groups[group]) {
            groups[group] = [];
            index = 0
          }
          //index++
          groups[group].push({
            id: ++index,
            ...row,
            cost: row.cost / this.showAmounts,
          });
          return groups;
        }, {})
      return res
    },

    treatmentsGroupTotals() {
      return Object.keys(this.treatmentsGroupedByPlan).reduce((totals, groupName) => {
        totals[groupName] = {
          totalCost: this.treatmentsGroupedByPlan[groupName].reduce((sum, row) => sum + row.cost, 0),
          //totalUnits: this.treatmentsGroupedByPlan[groupName].reduce((sum, row) => sum + row.units, 0)
        }
        return totals
      }, {});
    },

    getWorksheetColumns() {
      return [
        { header: this.$t('stdCols.id'), key: 'id', width: 5, num: true, digits: 0 },
        { header: this.$t('road_network.section'), key: 'section_description', width: 50, group: true },
        { header: this.$t('inventory.start_distance_m'), key: 'start_m', width: 10, num: true, digits: 0 },
        { header: this.$t('inventory.end_distance_m'), key: 'end_m', width: 10, num: true, digits: 0 },
        { header: this.$t('label.units'), key: 'unit_description', width: 10 },
        { header: this.$t('label.quantity'), key: 'units', width: 10, num: true, digits: 2 },
        { header: this.$t('treatment.treatment_desc'), key: 'treatment_type_description', width: 25 },
        { header: this.costLabel, key: 'cost', width: 20, num: true, digits: 2, totals: 'totalCost' },
      ];
    },

    getTableRows() {
      const columns = this.getWorksheetColumns
      let tableRows = '';

      for (const [groupName, group] of Object.entries(this.treatmentsGroupedByPlan)) {
        // Row with group name
        tableRows += `<tr style="background-color: rgb(255, 255, 0);">
            <td>&nbsp;</td><td colspan=7>${groupName}</td>
          </tr>`
        //          ${columns.map(col => `<td>${col.group ? groupName : '&nbsp;'}</td>`).join('')}

        // rows
        group.forEach((row) => {
          tableRows += `<tr>
              ${columns.map(col => col.num ? `<td class='numCell'>${numFormat(row[col.key], col.digits)}</td>`
            : `<td>${row[col.key]}</td>`).join('')}
              </tr>`;
        });

        // Group total row
        const cols = columns.map(col => {
          if (col.group) {
            return `<td>${this.$t('label.total')}</td>`;
          }

          if (col.totals) {
            return `<td class="numCell">${numFormat(this.treatmentsGroupTotals[groupName][col.totals])}</td>`;
          }

          return '<td>&nbsp;</td>';
        }).join('')
        tableRows += `<tr style="background-color: rgb(204, 221, 255);">${cols}</tr>`;
      }
      return tableRows;
    },
  },

  watch: {
    step(value) {
      if (value === 'third') this.loadPlanList()
    },

    searchQuery(value) {
      this.searchedData = value !== '' ? this.fuseSearch.search(this.searchQuery) : this.tableData
    }
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}


.cell-actions {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 80px;
  }
}

.md-just-icon {
  margin: 3px 0;
  min-width: 10px;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>