/* 
File: homo_sections.js
Description: provides routines for dealing with homogeneous sections.
*/
import Api from '@/api/sections'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: [],
    hsList: []
  },

  actions: {
    async LOAD_HS_LIST({ commit }, { year, is_approved }) {
      return await handleApiCall({
        request: Api.load_hs_lists(year, is_approved),
        caller: 'LOAD_HS_LIST',
        commit, mutation: 'SET_HS_LIST'
      });
    },

    async GET_HS_LIST_BY_ID({ commit }, homogeneous_section_list_id) {
      return await apiCall(Api.get_hs_list_by_id(lang, homogeneous_section_list_id), 'GET_HS_LIST_BY_ID');
    },

    async LOAD_ALL_HS({ commit }, homogeneous_section_list_id) {
      return await handleApiCall({
        request: Api.load_all_hs(homogeneous_section_list_id),
        caller: 'LOAD_ALL_HS',
        commit, mutation: 'SET_HS'
      });
    },

    async GENERATE_HS({ commit }, params) {
      const { year_from, year_to, min_hs_length, condition_index_threshold } = params
      return await apiCall(Api.generate_hs(year_from, year_to, min_hs_length, condition_index_threshold), 'GENERATE_HS', 201);
    },

    async DEL_HS_LIST({ commit }, homogeneous_section_list_id) {
      return await apiCall(Api.del_hs_list(homogeneous_section_list_id), 'DEL_HS_LIST');
    },

    async APPROVE_HS_LIST({ commit }, homogeneous_section_list_id) {
      return await apiCall(Api.approve_hs_list(homogeneous_section_list_id), 'APPROVE_HS_LIST', 201);
    },

    async CANCEL_HS_LIST({ commit }, homogeneous_section_list_id) {
      return await apiCall(Api.cancel_hs_list(homogeneous_section_list_id), 'CANCEL_HS_LIST', 201);
    },

    async RESET_HS_LIST({ commit }) {
      commit('SET_HS_LIST', [])
    },

    async RESET_HS({ commit }) {
      commit('SET_HS', [])
    },
  },

  mutations: {
    SET_HS_LIST(state, list) {
      state.list = list
    },

    SET_HS(state, list) {
      state.hsList = list
    },

    /*DEL_HS(state, id) {
      let ind = state.list.findIndex((row) => row.homogeneous_section_list_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },*/
  },

  getters: {
    // Unique years in the HomoSections list
    yearsInHSList: (state) => (addEmptyItem = true) => {
      // Get all unique years in the list, order and return as an array of objects for YearsDropdown
      const uniqueYears = [...new Set(state.list.reduce((acc, item) => {
        if (item.year) acc.push(item.year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => ({ id: year, description: year }));

      if (addEmptyItem) uniqueYears.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      return uniqueYears
    },

    approvedYearsInHSList: (state) => {
      // Get all unique years in the list, order and return as an  array of objects for BaseDropdown
      const uniqueYears = [...new Set(state.list.reduce((acc, item) => {
        if (item.year !== null && item.approved_by_user_id) acc.push(item.year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => (year));
      return uniqueYears
    },

    hsListByYear: (state) => (year) => {
      return !year ? state.list : state.list.filter(row => row.year === year)
    },

    //Homosections
    roadsInList: (state) => (region_id) => {
      // Get all unique years in the list, order and return as an array of objects for RoadsDropdown
      const uniqueRoads = Array.from(
        new Map(state.hsList
          .filter(item => !region_id || item.fk_region === region_id)
          .map(item => [item.fk_road, { id: item.fk_road, description: item.road_description }])
        ).values()
      ).sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }));

      uniqueRoads.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      return uniqueRoads
    },

    homoSectionsFiltered: (state) => (region_id, road_id) => {
      return state.hsList.filter(row => {
        const regionMatch = !region_id || row.fk_region === region_id
        const roadMatch = !road_id || row.fk_road === road_id
        return regionMatch && roadMatch
      }).sort((a, b) => a.start_distance - b.start_distance)
    },

    homoSectionSummary: (state, getters) => (region_id, road_id) => {
      const roadsCount = getters.roadsInList(region_id).length
      const filteredSections = getters.homoSectionsFiltered(region_id, road_id)
      const sectionsCount = filteredSections.length
      const sectionsLength = filteredSections.reduce((sum, row) => sum + row.length, 0) / 1000

      return {
        roadsCount,
        sectionsCount,
        sectionsLength
      }
    }

  }
}