<!--
File: ConditionIndexCriteria.vue
Description: show list/pivot of Condition Index Criteria.
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <BaseDropdown :label="$t('label.criteria')" v-model="selectedFilter" :items="dropDownList"
              :displayField="'description'" :valueField="'id'" />
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="addCondition"> {{ $t('buttons.add') }} </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-table class="paginated-table table-striped table-hover" :value="tableData" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('label.indicator_type')" md-sort-by="indicator_type">
              {{ $t(`condition.${item.indicator_type}`) }}
            </md-table-cell>
            <md-table-cell v-for="score in getScores" :key="score.val" :md-label="$t(`label.score_${score.val}`)">
              {{ item[`score_${score.val}`] }}
              <md-button v-if="item[`score_${score.val}_id`]" class="md-just-icon md-default md-simple"
                :title="$t('buttons.edit')" @click.native="editCondition(item[`score_${score.val}_id`])">
                <md-icon>edit</md-icon>
              </md-button>
              <!--md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item, score.val)">
                <md-icon>delete</md-icon>
              </md-button-->
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div class>
          <p class="card-category">
            {{
              $t('label.showing_from_to_of_entries', {
                from: to > 0 ? from + 1 : 0,
                to: to,
                total
              })
            }}
          </p>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label for="pages">{{ $t('label.per_page') }}</label>
            <md-select v-model="pagination.perPage" name="pages">
              <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
          :per-page="pagination.perPage" :total="total"></pagination>
      </md-card-actions>
    </md-card>

    <SingleCriteria :itemId="selectedItem" :show="showEditForm" @close=toggleEditForm(false) />
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import { Pagination } from '@/components'
  import BaseDropdown from '@/pages/Components/BaseDropdown.vue'
  import SingleCriteria from './ConditionIndexCriteriaSingle'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messagesMixin from '@/mixins/messagesMixin'

  export default {
    name: 'condition-index-criteria-list',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        selectedFilter: null,
        selectedItem: null,
        showEditForm: false,
        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 50, 100],
          total: 0
        },
        currentSort: 'indicator_type',
        currentSortOrder: 'asc',
      }
    },
    components: {
      Pagination,
      BaseDropdown,
      SingleCriteria
    },

    mounted() {
      this.$store.dispatch('LOAD_HISTORY').then((history) => {
        if (history.form === 'condition_index' && history.use) {
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.$nextTick(() => {
            this.searchQuery = history.data.searchQuery
            this.pagination.currentPage = history.data.currentPage
          })
          this.$store.dispatch('CLEAR_HISTORY')
        }
      })
      this.reloadData()
    },

    methods: {
      reloadData() {
        this.$store.dispatch('LOAD_CONDITION_INDEX_CRITERIA_PIVOT')
      },

      toggleEditForm(state) {
        this.showEditForm = state
        if (!state) this.reloadData()
      },

      save_history() {
        const hist = {
          form: 'condition_index',
          data: {
            searchQuery: this.searchQuery,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage
          }
        }
        this.$store.dispatch('SAVE_HISTORY', hist)
      },

      async editCondition(itemId) {
        this.save_history()
        this.selectedItem = itemId
        this.toggleEditForm(true)
      },

      addCondition() {
        this.save_history()
        this.selectedItem = null
        this.toggleEditForm(true)
      },

      async handleDelete(item, id) {
        this.save_history()
        const descr = this.$t(`condition.${item.indicator_type}`) + '-' + this.$t(`label.score_${id}`)
        const res = await this.deleteConfirmation(descr)
        if (!res.value) {
          return
        }

        const itemId = item[`score_${id}_id`]
        this.$store.dispatch('DEL_CONDITION_INDEX_CRITERIA', itemId).then(
          () => {
            this.reloadData()
            this.deletedMessage(descr)
          },
          (err) => {
            this.errorMessage(err.message)
          }
        )
      }
    },

    computed: {
      ...mapState({
        criteriaList: (state) => state.ConditionIndexCriteria.list,
      }),
      ...mapGetters(['amIAdmin', 'getDropDownList', 'getScores', 'criteriaListFiltered']),

      dropDownList() {
        return this.getDropDownList.map(item => ({ ...item, description: this.$t(item.description) }))
      },

      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },

      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) highBound = this.total
        return highBound
      },

      total() {
        return this.criteriaList.length
      },

      tableData() {
        return this.criteriaListFiltered(this.selectedFilter).slice(this.from, this.to)
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>