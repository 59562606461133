<!--
File: SectionsDropdown.vue
Description: show the dropdown combo with the road sections list.
-->
<template>
  <md-field>
    <label for="section_id">{{ label || $t('label.select_section') }}</label>
    <md-select v-model='selectedValue' id="section_id" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for='item in sectionsList' :key='item.id' :value='item.id'>
        {{ item.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'sections-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: Number },
      region_id: { default: null, type: Number },
      road_id: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,
        listLoaded: false
      }
    },

    methods: {
      reload() {
        this.listLoaded = false
        this.selectedValue = null
        const values = {
          region_id: this.region_id,
          road_id: this.road_id,
          show_all: !this.is_required
        }
        this.$store.dispatch('GET_SECTIONS_DROPDOWN', values).then(() => {
          this.listLoaded = true
          if (this.value && !this.selectedValue) this.selectedValue = this.value;
        })
      },

      onChange() {
        this.$emit('input', this.selectedValue, this.getSectionById(this.selectedValue))
      },
    },

    computed: {
      ...mapState({
        sectionsList: (state) => state.Dropdowns.sections
      }),
      
      ...mapGetters(['getSectionById']),
    },

    watch: {
      region_id(value) {
        this.reload()
      },

      road_id(value) {
        this.reload()
      },

      value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.selectedValue = newValue;
        }
      }
    }
  }
</script>
