<!--
File: UnitsDropdown.vue
Description: show the dropdown combo with the units dropdown list .
-->
<template>
  <md-field>
    <label for="units">{{ label || $t('label.units') }}</label>
    <md-select v-model='selectedValue' id="units" :required="is_required" @md-selected='onChange'>
      <md-option v-for='unit in units' :key='unit.id' :value='unit.id'>
        {{ unit.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'units-dropdown',

    props: {
      value: { default: null, type: Number },
      label: { default: null, type: String },
      is_required: { default: false, type: Boolean },
    },

    data() {
      return {
        selectedValue: this.value || null
      }
    },

    mounted() {
      this.$store.dispatch('LOAD_UNIT_LIST', !this.is_required).then(() => {
        if (this.value && !this.selectedValue) this.selectedValue = this.value;
      })
    },

    methods: {
      onChange() {
        const desc = this.units.find(el => el.id === this.selectedValue)?.name || ''
        this.$emit('input', this.selectedValue, desc)
      },
    },

    computed: {
      units() {
        return this.$store.state.RefData.unitsList;
      }
    },

    watch: {
      value(newValue) {
        if (newValue !== this.selectedValue) {
          this.selectedValue = newValue;
        }
      },
    }
  }
</script>