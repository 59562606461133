<!--
File: TreatmentTypeEditForm.vue
Description: form for adding/editing a single treatment.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="getClass('key')">
              <label for="key">{{ $t('treatment.treatment_key') }} ({{ ($t('translate.ru')) }})</label>
              <md-input id="key" v-model="key" type="text" data-vv-name="key" v-validate="modelValidations.key"
                required />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-30">
            <md-field :class="getClass('treatment_type_description')">
              <label for="description">{{ $t('treatment.treatment_description') }} ({{ ($t('translate.ru')) }})</label>
              <md-input id="description" v-model="treatment_type_description" type="text"
                data-vv-name="treatment_type_description" v-validate="modelValidations.treatment_type_description"
                required />
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="getClass('key_en')">
              <label for="key_en">{{ $t('treatment.treatment_key') }} ({{ ($t('translate.en')) }})</label>
              <md-input id="key_en" v-model="key_en" type="text" data-vv-name="key_en"
                v-validate="modelValidations.key_en" required />
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-30">
            <md-field :class="getClass('treatment_type_description_en')">
              <label for="description_en">{{ $t('treatment.treatment_description') }} ({{ ($t('translate.en')) }})</label>
              <md-input id="description_en" v-model="treatment_type_description_en" type="text"
                data-vv-name="treatment_type_description_en" v-validate="modelValidations.treatment_type_description_en"
                required />
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <UnitsDropdown :class="getClass('fk_unit')" v-model="fk_unit" data-vv-name="fk_unit"
              v-validate="modelValidations.fk_unit" :is_required="true" required />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-30">
            <WorkCategoriesDropdown :class="getClass('fk_work_category')" v-model="fk_work_category" :is_required="true"
              data-vv-name="fk_work_category" v-validate="modelValidations.fk_work_category" required />
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-small-size-20 md-size-20">
            <BaseDropdown :label="$t('label.expected_outcome')" v-model="expected_outcome" :items="surfaceTypes"
              :displayField="'description'" :valueField="'description'" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <BaseDropdown :label="$t('label.work_area')" v-model="work_area" :items="workAreas"
              :displayField="'description'" :valueField="'id'" />
          </div>
        </div>

        <div class="md-layout">
          <label for="swatches" :style="{ color: errors.has('color') ? '#f44336' : '#4caf50' }">
            {{ $t('treatment.select_color') }}
          </label>
          <VSwatches v-model="color" id="swatches" :swatches="swatches" shapes="circles" inline show-checkbox show-border
            v-validate="modelValidations.color" data-vv-name="color" />
        </div>

        <div class="md-layout">
          <span v-if="saveBtnDisabled" class="md-error">
            {{ $t('messages.errors_count', { count: errors.count() }) }}
          </span>
        </div>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { mapState } from 'vuex'
  import UnitsDropdown from '@/pages/Components/UnitsDropdown.vue'
  import WorkCategoriesDropdown from '@/pages/Components/WorkCategoriesDropdown.vue'
  import BaseDropdown from '@/pages/Components/BaseDropdown.vue'
  import VSwatches from 'vue-swatches'
  import 'vue-swatches/dist/vue-swatches.css'
  import permissions from "@/mixins/permissionsMixin"
  import messages from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'treatment-type-edit-form',
    mixins: [messages, permissions],

    data() {
      return {
        treatment_type_id: null,
        key: null,
        treatment_type_description: null,
        key_en: null,
        treatment_type_description_en: null,
        fk_unit: null,
        fk_work_category: null,
        expected_outcome: null,
        expected_outcome_en: null,
        color: '',
        work_area: null,

        swatches: ['red', 'orange', 'yellow', 'green', 'cyan', 'blue', 'magenta', 'black', ''],

        modelValidations: {
          key: { required: true, min: 2 },
          treatment_type_description: { required: true, min: 3 },
          key_en: { required: true, min: 2 },
          treatment_type_description_en: { required: true, min: 3 },
          fk_unit: { required: true, numeric: true, min_value: 1 },
          fk_work_category: { required: true, numeric: true, min_value: 1 },
          color: { required: true },
          work_area: { required: true, numeric: true, min_value: 1 },
        }
      }
    },

    props: {
      oper: String
    },

    components: {
      UnitsDropdown,
      WorkCategoriesDropdown,
      BaseDropdown,
      VSwatches
    },

    async mounted() {
      const { treatment_type_id = null } = this.$route.params
      this.treatment_type_id = treatment_type_id
      this.$store.dispatch('LOAD_SURFACE_TYPES')
      this.$store.dispatch('LOAD_WORK_AREAS')
      if (this.oper === 'upd' && treatment_type_id) {
        const res = await this.$store.dispatch('LOAD_TREATMENT_TYPE_BY_ID', treatment_type_id)
        const treatmentType = Array.isArray(res) ? res[0] : res
        this.key = treatmentType.key
        this.treatment_type_description = treatmentType.treatment_type_description
        this.key_en = treatmentType.key_en
        this.treatment_type_description_en = treatmentType.treatment_type_description_en
        this.fk_unit = treatmentType.fk_unit
        this.fk_work_category = treatmentType.fk_work_category
        this.expected_outcome = treatmentType.expected_outcome
        this.expected_outcome_en = treatmentType.expected_outcome_en
        this.color = treatmentType.color
        this.work_area = treatmentType.work_area
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const treatmentType = {
          key_en: this.key_en,
          treatment_type_description_en: this.treatment_type_description_en,
          key: this.key,
          treatment_type_description: this.treatment_type_description,
          fk_unit: this.fk_unit,
          fk_work_category: this.fk_work_category,
          expected_outcome: this.expected_outcome,
          expected_outcome_en: this.expected_outcome,
          color: this.color,
          work_area: this.work_area
        }

        const payload = this.oper === 'add' ? treatmentType : { id: this.treatment_type_id, treatment_type: treatmentType }
        let errDesc = ''
        try {
          await this.$store.dispatch(`${this.oper.toUpperCase()}_TREATMENT_TYPE`, payload)
        } catch (err) {
          errDesc = err.message
        }
        this.savedMessage(errDesc, this.$t('treatment.treatment_description'),
          this.treatment_type_description, this.treatment_type_description_en)

        this.$nextTick(() => {
          this.$validator.reset()
        })
        this.onClose()
      }
    },

    computed: {
      ...mapState({
        surfaceTypes: (state) => state.RefData.surface_types,
        workAreas: (state) => state.RefData.workAreas
      }),

      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    },

    watch: {
    },
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
  